import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import Grid from '@mui/material/Grid';
import { TDeleteContentFromPlaylistRequestSchema, TDraggableItem } from '@onesource/schemas';
import { FC } from 'react';
import { DraggableContentCard } from './card/draggable-content-card';
import { THandleSwitchParams } from '../reorder';

export type TSortableItemProps = {
  item: TDraggableItem;
  playlistId: string;
  enableDrag: boolean;
  onRemove?: (params: TDeleteContentFromPlaylistRequestSchema) => void;
  onMove?: (item: TDraggableItem) => void;
  isDashboard?: boolean;
  isShowRatting?: boolean;
  isActionDisabled?: boolean;
  isMandatory?: boolean;
  hasAlternative?: boolean;
  onChangeMandatory?: (params: THandleSwitchParams) => void;
  onChangeHasAlternative?: (params: THandleSwitchParams) => void;
};

export const SortableItem: FC<TSortableItemProps> = props => {
  const {
    item,
    enableDrag,
    onRemove,
    playlistId,
    onMove,
    isDashboard,
    isShowRatting,
    isActionDisabled,
    onChangeMandatory,
    onChangeHasAlternative,
    hasAlternative,
    isMandatory,
  } = props;
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: item.id,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <Grid item xs={12} ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <DraggableContentCard
        onMove={onMove}
        onRemove={onRemove}
        item={item}
        enableDrag={enableDrag}
        playlistId={playlistId}
        isDashboard={isDashboard}
        isShowRatting={isShowRatting}
        isActionDisabled={isActionDisabled}
        onChangeMandatory={onChangeMandatory}
        onChangeHasAlternative={onChangeHasAlternative}
        isMandatory={isMandatory}
        hasAlternative={hasAlternative}
      />
    </Grid>
  );
};
