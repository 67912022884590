import { RegisteredRouter, RoutePaths, useLocation, useNavigate, useSearch } from '@tanstack/react-router';
import { useCallback } from 'react';

type TParams = {
  path: RoutePaths<RegisteredRouter['routeTree']>;
  search: Record<string, unknown>;
};

export const useRouteUtils = () => {
  useLocation();
  const navigate = useNavigate();

  const navigateTo = useCallback(
    (params: TParams) => {
      const { path, search = {} } = params;
      navigate({ to: path, search });
    },
    [navigate],
  );

  const searchParams = useSearch({
    strict: false,
  });

  return {
    navigateTo,
    searchParams,
  };
};
