import { useMemo } from 'react';
import { useGetAllProfileQuery } from './use-get-profile-query';

export const useGetSubscriptionQuery = () => {
  const { data: userProfileData, isLoading: isLoadingProfileInfo } = useGetAllProfileQuery();

  const states = useMemo(() => {
    if (!userProfileData || isLoadingProfileInfo) {
      return {
        isLoadingProfileInfo,
        isFitSubscriptionActive: false,
        subscription: null,
      };
    }

    const { subscription } = userProfileData;

    return {
      isLoadingProfileInfo,
      isFitSubscriptionActive: !!subscription?.FIT?.isActive,
      isRycSubscriptionActive: !!subscription?.RYC?.isActive,
      subscription: subscription,
    };
  }, [userProfileData, isLoadingProfileInfo]);

  return states;
};

export type TUseGetSubscriptionQueryResult = ReturnType<typeof useGetSubscriptionQuery>;
