import DialogContent from '@mui/material/DialogContent';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { MvPlay } from '@onesource/icons';
import { IndicatorEnum, TContent, TContentRatingSet } from '@onesource/schemas';
import { useSaveContentRatingQuery } from '@onesource/services';
import { FC, useCallback, useState } from 'react';
import { RequirementIndicator } from '../../requirement-indicator';
import { DialogTitle } from '../dialog-title';

export type TAddRatingDialogProps = {
  content: TContent;
  open: boolean;
  onClose: () => void;
};

export const AddRatingDialog: FC<TAddRatingDialogProps> = props => {
  const theme = useTheme();
  const { mutateAsync: saveRatingMutation, isPending: isRatingSavePending } = useSaveContentRatingQuery();
  const { content, open, onClose } = props;

  const [updatedRating, setUpdatedRating] = useState<TContentRatingSet>({
    [IndicatorEnum.difficulty]: 2,
    [IndicatorEnum.intensity]: 2,
    [IndicatorEnum.nerdiness]: 2,
  });

  const handleRatingChange = useCallback((indicatorKey: string, newRating: number) => {
    setUpdatedRating(prev => ({
      ...prev,
      [indicatorKey]: newRating,
    }));
  }, []);

  const handleRatingSubmitHandler = useCallback(async () => {
    const data = await saveRatingMutation({
      contentId: content.id,
      rating: updatedRating,
    });

    setUpdatedRating(() => data);
    onClose();
  }, [content.id, onClose, saveRatingMutation, updatedRating]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle onClose={onClose}>Please add your own rating</DialogTitle>
      <DialogContent>
        <Typography variant="body2" sx={{ fontSize: '24px', fontWeight: '600' }}>
          Congratulation for doing this great class!
        </Typography>

        <Divider sx={{ my: 2 }} />

        <Box sx={{ flex: 1, py: 3 }}>
          {isRatingSavePending && (
            <Box
              sx={{
                position: 'absolute',
                height: `calc(100% - 2px)`,
                width: `calc(100% - 2px)`,
                top: 1,
                left: 1,
                zIndex: 1,
                borderRadius: '10px',
                background: 'rgba(0,0,0,0.5)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CircularProgress size={60} />
            </Box>
          )}

          <Stack
            direction="row"
            justifyContent="space-between"
            width="100%"
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              '&>div': { flex: '0 0 50%', boxSizing: 'border-box', mb: 3 },
              '&>div:nth-of-type(odd)': { paddingRight: 6.5 },
              '&>div:nth-of-type(even)': { paddingLeft: 6.5 },
            }}
          >
            <RequirementIndicator
              indicator={IndicatorEnum.difficulty}
              showName={true}
              isInteractive={true}
              onChange={newRating => handleRatingChange(IndicatorEnum.difficulty, newRating)}
              point={updatedRating[IndicatorEnum.difficulty]}
            />

            <RequirementIndicator
              indicator={IndicatorEnum.intensity}
              showName={true}
              isInteractive={true}
              onChange={newRating => handleRatingChange(IndicatorEnum.intensity, newRating)}
              point={updatedRating[IndicatorEnum.intensity]}
            />

            <RequirementIndicator
              indicator={IndicatorEnum.nerdiness}
              showName={true}
              isInteractive={true}
              onChange={newRating => handleRatingChange(IndicatorEnum.nerdiness, newRating)}
              point={updatedRating[IndicatorEnum.nerdiness]}
            />
          </Stack>
        </Box>

        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            disabled={isRatingSavePending}
            onClick={handleRatingSubmitHandler}
            endIcon={
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '50px',
                  width: '50px',
                  borderRadius: '50%',
                  background: theme => theme.palette.primary.main,
                  color: theme => theme.palette.common.white,
                  boxShadow: `5px 5px 15px ${theme.palette.common.black}20`,
                }}
              >
                <MvPlay />
              </Box>
            }
            sx={{ paddingLeft: '20px' }}
          >
            Rate
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
