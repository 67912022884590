import { DevTool } from '@hookform/devtools';
import { RiLockLine } from 'react-icons/ri';
import { zodResolver } from '@hookform/resolvers/zod';
import LoadingButton from '@mui/lab/LoadingButton';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2';
import { LoginSchema, TLogin } from '@onesource/schemas';
import { isLoggedIn, setSession, useLogin } from '@onesource/services';
import { LinkOptions } from '@tanstack/react-router';
import { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { FormProvider, RHFTextField, useGetByDevice } from '@onesource/components';
import { SHOW_DEV_TOOLS } from 'config';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';
import { FaChevronRight, FaRegUser } from 'react-icons/fa';
import { darken, useTheme } from '@mui/material';

export const Login: FC = () => {
  const theme = useTheme();
  const { mutateAsync: loginMutation, isPending } = useLogin();
  const isLogin = isLoggedIn();
  const { isMobile, isTablet } = useGetByDevice();
  const methods = useForm<TLogin>({
    resolver: zodResolver(LoginSchema),
    defaultValues: {
      username: '',
      password: '',
    },
    mode: 'all',
  });

  const {
    control,
    handleSubmit,
    formState: { isValid, isDirty },
  } = methods;

  const onSubmit = handleSubmit(async data => {
    await loginMutation(data, {
      onSuccess: async res => {
        const { data: user, access_token: token } = res.data;
        const homePageLink: LinkOptions['to'] = '/';

        setSession({ user, token });
        window.location.href = homePageLink;
      },
    });
  });

  useEffect(() => {
    if (isLogin) {
      const homePageLink: LinkOptions['to'] = '/';
      window.location.href = homePageLink;
    }
  }, [isLogin]);

  const isDisabled = !isDirty || !isValid || isPending;

  return (
    <Box
      sx={{
        position: 'relative',
        height: '100vh',
        ml: isTablet ? -30 : 0,
        bgcolor: isMobile ? darken('#A3ABBF', 0.09) : undefined,
        background: isMobile ? undefined : `url('/login-bg.jpeg') no-repeat center/cover`,
      }}
    >
      {isMobile && (
        <Box
          sx={{
            position: 'absolute',
            top: { xs: -120, sm: -150 },
            left: -80,
            right: 0,
            height: '100dvh',
            background: `url('/login-bg-mobile.jpg') no-repeat center/cover`,
          }}
        />
      )}
      <Container
        maxWidth="xl"
        sx={{
          height: '100dvh',
          pt: { xs: 0, md: 15 },
          display: 'flex',
          justifyContent: { xs: 'center', md: 'start' },
          alignItems: { xs: 'center', md: 'start' },
        }}
      >
        <Grid
          sx={{
            ml: { xs: 0, md: 30, lg: 10 },
            zIndex: 1,
            width: { xs: 380, md: 400 },
            height: { sm: 350, lg: undefined },
            overflow: 'hidden',
            borderRadius: { xs: 5, md: 10 },
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background: theme.palette.common.white,
          }}
        >
          <FormProvider methods={methods} onSubmit={onSubmit}>
            <Typography
              variant="h5"
              sx={{ my: 3, fontWeight: 600, color: theme.palette.common.black, textAlign: 'center' }}
            >
              Login To Your Account
            </Typography>
            <Grid
              container
              spacing={2.5}
              alignItems="center"
              sx={{ width: { xs: 380, md: 400 }, padding: 3.5, background: theme.palette.grey[100] }}
              justifyContent="center"
            >
              <Grid xs={12}>
                <RHFTextField
                  data-testid="username"
                  name="username"
                  placeholder="Email/Username"
                  InputProps={{
                    style: {
                      padding: '0px',
                      boxShadow: `0px 0px 11px ${theme.palette.common.black}9`,
                      backgroundColor: 'white',
                    },
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        sx={{ border: 2, p: 1.5, width: 22, height: 22, borderRadius: '50%' }}
                      >
                        <FaRegUser size={22} />
                      </InputAdornment>
                    ),
                  }}
                  sx={{
                    '& .MuiOutlinedInput-notchedOutline': {
                      border: 0,
                    },
                  }}
                />
              </Grid>

              <Grid xs={12}>
                <RHFTextField
                  data-testid="password"
                  name="password"
                  placeholder="Password"
                  type="password"
                  InputProps={{
                    style: {
                      padding: '0px',
                      boxShadow: `0px 0px 11px ${theme.palette.common.black}9`,
                      backgroundColor: theme.palette.common.white,
                    },
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        sx={{ border: 2, p: 1.5, width: 22, height: 22, borderRadius: '50%' }}
                      >
                        <RiLockLine size={22} />
                      </InputAdornment>
                    ),
                  }}
                  sx={{
                    '& .MuiOutlinedInput-notchedOutline': {
                      border: 0,
                    },
                  }}
                />
              </Grid>

              <Grid xs={12}>
                <LoadingButton
                  disabled={isDisabled}
                  autoFocus
                  variant="contained"
                  size="large"
                  type="submit"
                  color="primary"
                  data-testid="submit-button"
                  endIcon={
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '50px',
                        width: '50px',
                        borderRadius: '50%',
                        background: theme => (isDisabled ? theme.palette.grey[500] : theme.palette.primary.main),
                        color: theme => theme.palette.common.white,
                        boxShadow: theme => `5px 5px 15px ${theme.palette.common.black}20`,
                      }}
                    >
                      <FaChevronRight />
                    </Box>
                  }
                  onClick={onSubmit}
                  fullWidth
                >
                  Login
                </LoadingButton>
              </Grid>
            </Grid>
          </FormProvider>
        </Grid>
        {SHOW_DEV_TOOLS && <DevTool control={control} />}
      </Container>
    </Box>
  );
};
