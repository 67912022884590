import { alpha, darken, SxProps, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardMedia, { CardMediaProps } from '@mui/material/CardMedia';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { ContentCategoryBadge } from '@onesource/components';
import { MvPlay } from '@onesource/icons';
import { TCategory } from '@onesource/schemas';
import { Link, RegisteredRouter, RoutePaths } from '@tanstack/react-router';
import { memo, useState } from 'react';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';

export type TMonthsChallenge = {
  title: string;
  subtitle: string;
  description: string;
  videoBadge: string;
  buttonLink: RoutePaths<RegisteredRouter['routeTree']>;
  buttonLabel: string;
  cardMedia: CardMediaProps;
  category: TCategory;
  sx?: SxProps;
};

export const MonthChallenge = memo((props: TMonthsChallenge) => {
  const theme = useTheme();
  const { title, subtitle, description, videoBadge, buttonLink, buttonLabel, cardMedia, category, sx } = props;
  const [isDescriptionVisible, setIsDescriptionVisible] = useState(false);

  const handleToggleDescription = () => {
    setIsDescriptionVisible(!isDescriptionVisible);
  };

  return (
    <Box>
      <Container maxWidth="xl" sx={{ my: { xs: 2, md: 3 }, pr: { xs: 0, md: 3 } }}>
        <Grid
          container
          spacing={{ xs: 2, md: 4 }}
          sx={{
            padding: { xs: '30px 0 0', md: '40px 0 0' },
            background: theme => theme.palette.common.white,
            flexDirection: { xs: 'column-reverse', md: 'row' },
            ...sx,
          }}
        >
          <Grid item md={6} xs={12} sx={{ pr: { xs: 2 } }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                mb: { xs: 1, md: 0 },
              }}
            >
              <Typography
                variant="h2"
                sx={{
                  mt: { xs: 0, md: 11 },
                  mb: { xs: 0, md: 3 },
                }}
              >
                {title}
              </Typography>
              <Box
                sx={{
                  display: { xs: 'flex', md: 'none' },
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    background: alpha(theme.palette.primary.light, 0.12),
                    height: '40px',
                    width: '40px',
                    borderRadius: '50%',
                    cursor: 'pointer',
                  }}
                  onClick={handleToggleDescription}
                >
                  {isDescriptionVisible ? <IoIosArrowUp size={25} /> : <IoIosArrowDown size={25} />}
                </Box>
              </Box>
            </Box>
            <Box sx={{ display: { xs: isDescriptionVisible ? 'block' : 'none', md: 'block' } }}>
              <Typography
                variant="body1"
                sx={{
                  mb: 1,
                  color: theme => theme.palette.primary.main,
                }}
              >
                {subtitle}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  color: theme => darken(theme.palette.common.white, 0.799),
                  lineHeight: 1.8,
                }}
              >
                <span dangerouslySetInnerHTML={{ __html: description }} />
              </Typography>
            </Box>

            <Box sx={{ display: 'flex', justifyContent: { xs: 'center', md: 'flex-start' }, my: 3 }}>
              <Link to={buttonLink}>
                <Button
                  endIcon={
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '50px',
                        width: '50px',
                        borderRadius: '50%',
                        background: theme => theme.palette.primary.main,
                        color: theme => theme.palette.common.white,
                        boxShadow: theme => `5px 5px 15px ${theme.palette.common.black}20`,
                      }}
                    >
                      <MvPlay />
                    </Box>
                  }
                  sx={{ paddingLeft: '20px' }}
                >
                  {buttonLabel}
                </Button>
              </Link>
            </Box>
          </Grid>
          <Grid item md={6} xs={12} sx={{ position: 'relative', padding: { xs: '0 !important', md: 0 } }}>
            <Box
              sx={{
                position: 'absolute',
                zIndex: 0,
                background: theme => theme.palette.primary.main,
                width: '40%',
                height: '30%',
                left: '60%',
                borderRadius: { xs: '30px 0 0 30px', md: '30px 0 30px 30px' },
              }}
            >
              <Typography
                variant="body1"
                sx={{ color: theme => theme.palette.common.white, pt: '10px', textAlign: 'center' }}
              >
                {videoBadge}
              </Typography>
            </Box>
            <Card
              sx={{
                pt: 5.5,
                pb: { xs: 0, md: 9.5 },
                background: 'transparent',
                position: 'relative',
                zIndex: 1,
                boxShadow: 0,
              }}
            >
              <Box sx={{ position: 'absolute', top: '60px', left: '15px' }}>
                <ContentCategoryBadge category={category} />
              </Box>
              <CardMedia
                component="img"
                {...cardMedia}
                image={cardMedia.image ?? 'https://placehold.co/750x419'}
                srcSet={`
                  ${cardMedia.image ?? 'https://placehold.co/750x419'} 600w
                `}
                sizes="(max-width: 600px) 100vw, (min-width: 601px) 50vw"
                sx={{ borderRadius: { xs: 0, md: '30px 0 30px 30px' }, height: { sm: '400px', md: '450px' } }}
              />
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
});
