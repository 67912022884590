import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import {
  AddEditPlaylistDialog,
  Content,
  DataStateHandler,
  PostFilterSidebar,
  PostLoading,
  useGetByDevice,
  useSetSearchParams,
} from '@onesource/components';
import { contentFilterDefaultValues, EAppNameEnum, InstructorTypeEnum, TContent } from '@onesource/schemas';
import {
  useGetContentsQuery,
  useGetInstructorsQuery,
  useGetSubscriptionQuery,
  useGetUserWatchedContentIDsQuery,
} from '@onesource/services';
import { usePlaylistMenuService } from 'components/add-to-playlist/service';
import { usePlaylistAction } from 'hooks/use-playlist-actions';
import { FC, useCallback, useEffect } from 'react';
import { ContentForToday } from '../../components/content-for-today';
import { TeachersFilterNav } from '../../components/teacher-filter-nav';
import { useRouteUtils } from '../../components/use-route-utils';
import { ClassesHeader } from 'components/sections/classes-header';

export const ClassesByTeachers: FC = () => {
  const { searchParams } = useRouteUtils();
  const { isPortable } = useGetByDevice();

  const {
    contextClassId,
    onAddToPlaylist,
    createPlaylist,
    isCreatePending,
    onClickCreate,
    onClosePlaylistDialog,
    isCreatePlaylistDialogOpen,
  } = usePlaylistAction();

  const { data: watchedContents } = useGetUserWatchedContentIDsQuery();

  const { data: instructorsData, isLoading } = useGetInstructorsQuery({
    filters: {
      type: InstructorTypeEnum.REGULAR,
    },
  });

  const searchFilterUtils = useSetSearchParams({
    initialFilters: {
      ...contentFilterDefaultValues,
      ...searchParams,
      scrollTop: '1',
    },
    resetFilters: { ...contentFilterDefaultValues, scrollTop: '1' },
  });

  const { patchFilter, filters } = searchFilterUtils;

  const contentStates = useGetContentsQuery({ filters: searchFilterUtils.filters, app: EAppNameEnum.FIT });

  const { isFitSubscriptionActive } = useGetSubscriptionQuery();

  const handleTeacherSelect = useCallback(
    (instructorId: string) => {
      patchFilter({ page: '1', instructorIds: instructorId });
    },
    [patchFilter],
  );

  const onNext = useCallback(
    (page: number) => {
      patchFilter({ page: page.toString() });
    },
    [patchFilter],
  );

  const onPrev = useCallback(
    (page: number) => {
      patchFilter({ page: page.toString() });
    },
    [patchFilter],
  );

  const filterInstructorIds = filters.instructorIds;
  const defaultSelectInstructorId = instructorsData?.items[0]?.id;

  useEffect(() => {
    if (!Boolean(filterInstructorIds) && defaultSelectInstructorId) {
      patchFilter({ instructorIds: String(defaultSelectInstructorId) });
    }
  }, [filterInstructorIds, defaultSelectInstructorId, patchFilter]);

  return (
    <>
      <ClassesHeader searchFilterUtils={searchFilterUtils} />
      <Box sx={{ pt: { xs: 2.5, md: 3 }, pb: 4, background: theme => theme.palette.common.white, overflow: 'hidden' }}>
        <Container maxWidth="xl">
          <Grid container spacing={{ xs: 2, md: 4 }}>
            <Grid item xs={12} sx={{ mb: { xs: 0, md: 5 } }}>
              <TeachersFilterNav
                slides={instructorsData?.items || []}
                onSelectTeacher={handleTeacherSelect}
                selected={filterInstructorIds}
                isLoading={isLoading}
              />
            </Grid>
            <Grid item md={3} sm={6} xs={12} sx={{ display: isPortable ? 'none' : 'block' }}>
              <PostFilterSidebar searchFilterUtils={searchFilterUtils} hide={['instructor']} />
            </Grid>
            <Grid item md={12} sm={12} xs={12} lg={9}>
              <DataStateHandler
                queryResult={contentStates}
                wrapperSx={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '100%',
                }}
                onNext={onNext}
                onPrev={onPrev}
                entity="classes by instructors"
                loadingProps={{
                  loadingWrapperProps: {
                    flexDirection: 'row',
                  },
                  loadingItemProps: {
                    sm: 4,
                    md: 4,
                    xs: 12,
                  },
                }}
                gridProps={{ md: 4, sm: 6, xs: 12 }}
                pagination={!!contentStates.data?.meta.totalPages}
                LoadingComponent={PostLoading}
                IterativeComponent={(props: { item: TContent }) => {
                  // INFO: we need to fix this
                  // eslint-disable-next-line react-hooks/rules-of-hooks
                  const { setPlaylistMenu } = usePlaylistMenuService();

                  // eslint-disable-next-line react-hooks/rules-of-hooks
                  const onClickAddPlaylistHandler = useCallback(
                    (anchorEl: HTMLElement, contentId: string) => {
                      setPlaylistMenu({
                        contentId,
                        anchorEl,
                        onAddToPlaylist,
                        onClickCreate,
                      });
                    },
                    [setPlaylistMenu],
                  );

                  return (
                    <Content
                      hasWatched={!!watchedContents?.has(props.item.id)}
                      linkProps={{
                        search: {
                          instructorId: props.item.instructor.id,
                        },
                      }}
                      content={props.item}
                      isLocked={!isFitSubscriptionActive}
                      onClickAddPlaylist={onClickAddPlaylistHandler}
                    />
                  );
                }}
              />
              {/* <ClassesDataStateHandler
              isFitSubscriptionActive={isFitSubscriptionActive}
              onClickAddPlaylist={onClickAddPlaylistHandler}
              onNext={onNext}
              onPrev={onPrev}
              queryResult={contentStates}
              sx={{
                display: 'flex',
                alignItems: 'center',
                width: '100%',
              }}
            /> */}
            </Grid>
            <ContentForToday />
          </Grid>

          <AddEditPlaylistDialog
            isPending={isCreatePending}
            open={isCreatePlaylistDialogOpen}
            onClose={onClosePlaylistDialog}
            onSave={createPlaylist}
            contentId={contextClassId}
          />
        </Container>
      </Box>
    </>
  );
};
