import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { ChangeEvent, FC, FormEvent, useCallback, useEffect, useState } from 'react';
import { FaFloppyDisk } from 'react-icons/fa6';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { useGetByDevice } from '../../hooks';

export type TOnSaveParams = {
  name: string;
  contentId?: string;
};

export type TAddEditPlaylistDialog = {
  open: boolean;
  onClose: () => void;
  onSave: (params: TOnSaveParams, isEditMode: boolean) => void;
  isPending?: boolean;
  formValues?: {
    name: string;
  };
  contentId?: string;
};

export const AddEditPlaylistDialog: FC<TAddEditPlaylistDialog> = props => {
  const { onClose, open, onSave, formValues, isPending, contentId } = props;
  const isEditMode = Boolean(formValues?.name);
  const [name, setName] = useState('');

  const { isXsMobile } = useGetByDevice();

  const onChangeTitle = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  }, []);

  const onSubmitHandler = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      onSave({ name, contentId }, isEditMode);
    },
    [onSave, name, contentId, isEditMode],
  );

  useEffect(() => {
    if (formValues?.name) {
      setName(formValues?.name);
    }
  }, [formValues?.name]);

  useEffect(() => {
    if (!open) {
      setName('');
    }
  }, [open]);

  const editLabel = isXsMobile ? 'Edit' : 'Edit Playlist';
  const createLabel = isXsMobile ? 'Create' : 'Create Playlist';

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>New Playlist</DialogTitle>
      <DialogContent>
        <form onSubmit={onSubmitHandler}>
          <TextField title="Title" placeholder="Cardio" value={name} onChange={onChangeTitle} autoFocus />
        </form>
      </DialogContent>
      <DialogActions sx={{ p: 2 }}>
        <Button
          variant="outlined"
          sx={{
            minWidth: 'auto',
            color: 'primary.main',
            borderColor: 'primary.main',
            py: 1,
            px: 2.7,
          }}
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          startIcon={<FaFloppyDisk />}
          sx={{
            minWidth: 'auto',
            py: 1.2,
            px: 2.8,
          }}
          onClick={() => onSave({ name, contentId }, isEditMode)}
        >
          {isEditMode ? editLabel : createLabel}
        </Button>
      </DialogActions>

      {isPending && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            background: 'rgba(0, 0, 0, 0.5)',
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </Dialog>
  );
};
