import * as React from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useTheme } from '@mui/material';

interface IProps {
  anchorEl: HTMLElement | null;
  open: boolean;
  handlePopoverClose: () => void;
}

export default function InfoPopover(props: IProps) {
  const { anchorEl, open, handlePopoverClose } = props;
  const theme = useTheme();
  return (
    <div>
      <Popover
        id="mouse-over-popover"
        sx={{ pointerEvents: 'none' }}
        slotProps={{
          paper: {
            sx: {
              overflow: 'visible',
              '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 1,
                right: { xs: 35, md: 50 },
                width: 22,
                height: 22,
                backgroundColor: theme.palette.primary.main,
                transform: 'translateY(-50%) rotate(50deg)',
              },
            },
          },
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: -30,
          horizontal: 230,
        }}
        onClose={handlePopoverClose}
      >
        <Card sx={{ width: 300 }}>
          <DialogTitle id="mouse-over-popover">Workouts + lives</DialogTitle>
          <CardContent>
            <Typography variant="body2">
              Earn points for each time you workout, special bonus for joining a live class
            </Typography>
          </CardContent>
        </Card>
      </Popover>
    </div>
  );
}
