import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import Grid from '@mui/material/Grid';
import {
  TDeleteContentFromPlaylistRequestSchema,
  TDraggableItem,
  TRYCContentPath,
  TUpdateStepContentSchemaFE,
} from '@onesource/schemas';
import { FC, useCallback } from 'react';
import { SortableItem } from './sortable-item';
import { THandleSwitchParams } from '../reorder';

interface ISortableContentProps {
  items: TDraggableItem[];
  setSortedItems?: (contents: TDraggableItem[]) => void;
  enableDrag?: boolean;
  playlistId: string;
  onChangeOrder?: (event: DragEndEvent) => void;
  onRemove?: (params: TDeleteContentFromPlaylistRequestSchema) => void;
  onMove?: (item: TDraggableItem) => void;
  isDashboard?: boolean;
  isShowRatting?: boolean;
  isActionDisabled?: boolean;
  onChangeMandatory?: (item: THandleSwitchParams) => void;
  onChangeHasAlternative?: (item: THandleSwitchParams) => void;
  step?: TUpdateStepContentSchemaFE[];
}

export const SortableContents: FC<ISortableContentProps> = props => {
  const {
    items,
    enableDrag = false,
    setSortedItems,
    onRemove,
    onMove,
    playlistId,
    onChangeOrder,
    isDashboard,
    isShowRatting,
    isActionDisabled,
    onChangeHasAlternative,
    onChangeMandatory,
    step,
  } = props;
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const handleDragEnd = useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event;

      if (active.id !== over?.id) {
        const oldIndex = items.findIndex(item => item.id === active.id);
        const newIndex = items.findIndex(item => item.id === over?.id);
        const sortedContent = arrayMove(items, oldIndex, newIndex);
        setSortedItems && setSortedItems(sortedContent);
      }
      onChangeOrder?.(event);
    },
    [setSortedItems, onChangeOrder, items],
  );

  return (
    <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
      <SortableContext items={items} strategy={verticalListSortingStrategy} disabled={!enableDrag}>
        <Grid container spacing={2}>
          {items.map(item => {
            const find = step?.find(stepItem => stepItem.content.id === item.content.id);
            return (
              <SortableItem
                onRemove={onRemove}
                onMove={onMove}
                key={item.content.id}
                item={item}
                enableDrag={enableDrag}
                playlistId={playlistId}
                isDashboard={isDashboard}
                isShowRatting={isShowRatting}
                isActionDisabled={isActionDisabled}
                onChangeMandatory={({ checked, contentId }) =>
                  !!onChangeMandatory && onChangeMandatory({ checked, contentId, path: find?.path as TRYCContentPath })
                }
                onChangeHasAlternative={({ checked, contentId }) =>
                  !!onChangeHasAlternative &&
                  onChangeHasAlternative({ checked, contentId, path: find?.path as TRYCContentPath })
                }
                isMandatory={find?.isMandatory}
                hasAlternative={find?.hasAlternatives}
              />
            );
          })}
        </Grid>
      </SortableContext>
    </DndContext>
  );
};
