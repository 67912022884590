import type { SVGProps } from 'react';
const SvgLockWithCircle = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 45 45"
    fontSize="1rem"
    {...props}
  >
    <circle cx={22.343} cy={22.343} r={21.593} stroke="#fff" strokeWidth={1.5} />
    <path
      fill="currentColor"
      d="M22.004 30.309H17.87c-1.306-.002-2.132-.823-2.133-2.124-.002-2.336.023-4.672-.01-7.007-.018-1.213.93-2.2 2.146-2.116.257.018.306-.08.302-.312a53 53 0 0 1-.002-1.975 3.82 3.82 0 0 1 3.409-3.75 3.82 3.82 0 0 1 4.15 2.919c.085.362.092.747.104 1.122.017.526.003 1.053.005 1.58.002.479-.045.406.403.413.922.013 1.664.528 1.927 1.368a2.4 2.4 0 0 1 .099.698q.01 3.557.003 7.113c-.003 1.232-.843 2.066-2.08 2.07-1.396.004-2.792 0-4.189.001zm-.027-1.581h4.161c.415 0 .543-.125.543-.528q.003-3.516 0-7.032c0-.407-.124-.527-.543-.527-.53.001-1.06.012-1.59.012q-3.339-.006-6.679-.012c-.419 0-.543.12-.543.526v7.032c0 .405.128.529.543.529zm2.264-9.702c0-.8.022-1.572-.005-2.342-.041-1.173-1.037-2.092-2.229-2.093s-2.19.92-2.234 2.089c-.027.745-.008 1.492-.01 2.238 0 .032.014.065.024.107h4.454"
    />
    <path
      fill="currentColor"
      d="M21.997 25.468c-.212 0-.424.01-.635-.002a.783.783 0 0 1-.76-.764c-.011-.402.277-.765.694-.793.474-.031.954-.033 1.428.003.418.032.695.396.678.803a.773.773 0 0 1-.744.749c-.22.013-.44.002-.661.002z"
    />
  </svg>
);
export default SvgLockWithCircle;
