import Box from '@mui/material/Box';
import CardMedia from '@mui/material/CardMedia';
import { FC } from 'react';

type TInstructorPortraitContainerProps = {
  slug: string;
};

export const InstructorPortraitContainer: FC<TInstructorPortraitContainerProps> = props => {
  const { slug } = props;
  switch (slug) {
    case 'alison-tjong':
      return (
        <Box
          sx={{
            width: { xs: '337px', md: '420px' },
            height: { xs: '388px', md: '500px' },
            position: 'relative',
            top: { xs: 40, md: undefined },
            overflow: 'hidden',
          }}
        >
          <CardMedia
            sx={{
              width: '100%',
              height: '100%',
              objectFit: 'contain',
              objectPosition: 'center',
            }}
            component="img"
            src="https://members.laurenohayon.com/wp-content/uploads/2025/02/alison-tjong-public.webp"
          />
        </Box>
      );
    case 'claire-russell-burns':
      return (
        <Box
          sx={{
            width: { xs: '337px', md: '420px' },
            height: { xs: '388px', md: '500px' },
            position: 'relative',
            top: { xs: 40, md: undefined },
            overflow: 'hidden',
          }}
        >
          <CardMedia
            sx={{
              width: '100%',
              height: '100%',
              objectFit: 'contain',
              objectPosition: 'center',
            }}
            component="img"
            src="https://members.laurenohayon.com/wp-content/uploads/2025/02/claire-russel-public.webp"
          />
        </Box>
      );
    case 'emma-riedo':
      return (
        <Box
          sx={{
            width: { xs: '337px', md: '420px' },
            height: { xs: '388px', md: '500px' },
            position: 'relative',
            top: { xs: 40, md: undefined },
            overflow: 'hidden',
          }}
        >
          <CardMedia
            sx={{
              width: '100%',
              height: '100%',
              objectFit: 'contain',
              objectPosition: 'center',
            }}
            component="img"
            src="https://members.laurenohayon.com/wp-content/uploads/2025/02/emma-riedo-public.webp"
          />
        </Box>
      );
    case 'jessica-gradhandt':
      return (
        <Box
          sx={{
            width: { xs: '337px', md: '420px' },
            height: { xs: '388px', md: '500px' },
            position: 'relative',
            top: { xs: 40, md: undefined },
            overflow: 'hidden',
          }}
        >
          <CardMedia
            sx={{
              width: '100%',
              height: '100%',
              objectFit: 'contain',
              objectPosition: 'center',
            }}
            component="img"
            src="https://members.laurenohayon.com/wp-content/uploads/2025/02/jessica-gradhandt-public.webp"
          />
        </Box>
      );
    case 'louisa-thomas':
      return (
        <Box
          sx={{
            width: { xs: '337px', md: '420px' },
            height: { xs: '388px', md: '500px' },
            position: 'relative',
            top: { xs: 40, md: undefined },
            overflow: 'hidden',
          }}
        >
          <CardMedia
            sx={{
              width: '100%',
              height: '100%',
              objectFit: 'contain',
              objectPosition: 'center',
            }}
            component="img"
            src="https://members.laurenohayon.com/wp-content/uploads/2025/02/louisa-thomas-public.webp"
          />
        </Box>
      );
    case 'sara-howard':
      return (
        <Box
          sx={{
            width: { xs: '337px', md: '420px' },
            height: { xs: '388px', md: '500px' },
            position: 'relative',
            top: { xs: 40, md: undefined },
            overflow: 'hidden',
          }}
        >
          <CardMedia
            sx={{
              width: '100%',
              height: '100%',
              objectFit: 'contain',
              objectPosition: 'center',
            }}
            component="img"
            src="https://members.laurenohayon.com/wp-content/uploads/2025/02/sara-howard-public.webp"
          />
        </Box>
      );
    case 'shona-bohmer':
      return (
        <Box
          sx={{
            width: { xs: '337px', md: '420px' },
            height: { xs: '388px', md: '500px' },
            position: 'relative',
            top: { xs: 40, md: undefined },
            overflow: 'hidden',
          }}
        >
          <CardMedia
            sx={{
              width: '100%',
              height: '100%',
              objectFit: 'contain',
              objectPosition: 'center',
            }}
            component="img"
            src="https://members.laurenohayon.com/wp-content/uploads/2025/02/shona-bohmer-public.webp"
          />
        </Box>
      );
  }
};
