import Button from '@mui/material/Button';
import { alpha, darken, SxProps, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import MuiLink from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { BackButton, NotFoundCard, useGetByDevice } from '@onesource/components';
import { Facebook, Instagram, Linkedin, Location, Website } from '@onesource/icons';
import { IPaginationNew, MediaTypeEnum, TContent, TInstructor } from '@onesource/schemas';
import { isLoggedIn } from '@onesource/services';
import { Link } from '@tanstack/react-router';
import { InstructorSaleBanner } from 'components/instructor-sale-banner';
import { WP_MEMBERS_SITE } from 'config';
import { FC, useMemo } from 'react';
import { PiTiktokLogo, PiTwitterLogo, PiYoutubeLogo } from 'react-icons/pi';
import { Breadcrumbs } from '../../breadcrumbs';
import { RelatedPosts } from '../related-posts';
import { SlArrowRight } from 'react-icons/sl';

export type TSingleTeacherDetailsProps = {
  instructor: TInstructor;
  sx?: SxProps;
  instructorContent: IPaginationNew<TContent>;
};
export const InstructorDetailsBody: FC<TSingleTeacherDetailsProps> = props => {
  const { instructor, sx, instructorContent } = props;
  const theme = useTheme();
  const { isDesktop } = useGetByDevice();

  const instructorName = `${instructor?.firstName} ${instructor?.lastName}`;

  const pointStyle = {
    color: { xs: darken(theme.palette.common.white, 0.799), md: theme.palette.common.white },
    borderLeft: { xs: `3px solid ${theme.palette.primary.main}`, md: `3px solid` },
    fontSize: { xs: '18px', md: '22px' },
    lineHeight: 1.5,
    fontWeight: { xs: 400, md: 500 },
    pl: 2,
    mt: { xs: 1.5, md: 0 },
    mb: { xs: 1.5, md: 0 },
    width: '180px',
  };

  const SliderSettings = {
    slidesToShow: 3,
    slidesToScroll: 3,
    arrows: true,
    infinite: false,
    centerMode: false,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          centerMode: false,
          infinite: true,
        },
      },
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          centerMode: false,
          infinite: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: '15%',
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: '5%',
          infinite: true,
        },
      },
    ],
  };

  const linkedin = MediaTypeEnum.linkedin;
  const facebook = MediaTypeEnum.facebook;
  const instagram = MediaTypeEnum.instagram;
  const twitter = MediaTypeEnum.twitter;
  const tiktok = MediaTypeEnum.tiktok;
  const youtube = MediaTypeEnum.youtube;
  const website = MediaTypeEnum.website;

  const isLogIn = isLoggedIn();
  const affiliateId = instructor?.affiliateId;

  const subscriptionLink = `${WP_MEMBERS_SITE}/payment/ryc-fit/${affiliateId ? `?rxf=${affiliateId}` : ''}`;

  const orderedSocialLinks = useMemo(() => {
    if (!instructor || !instructor.socialMedia) {
      return [];
    }

    // Based on this array order social links will be rendered on the page
    const orderToShowOnPage = [website, instagram, facebook, linkedin, twitter, tiktok, youtube];

    return orderToShowOnPage.map(name => instructor.socialMedia?.find(item => item.mediaType === name)).filter(Boolean);
  }, [facebook, instagram, instructor, linkedin, tiktok, twitter, website, youtube]);

  return (
    <>
      <Box sx={{ ...sx, overflowX: 'hidden' }}>
        <Container maxWidth="xl">
          <Grid
            container
            spacing={14}
            sx={{
              background: theme => theme.palette.common.white,
              ...sx,
            }}
          >
            <Grid item md={6} xs={12} sx={{ mb: '34px' }}>
              <Box
                sx={{
                  pb: { xs: 2, md: 5 },
                  mt: 4,
                  justifyContent: { xs: 'center', md: 'start' },
                  display: 'flex',
                }}
              >
                {isLogIn && (
                  <Breadcrumbs>
                    <BackButton color="black" />
                    <Link style={{ textDecoration: 'none', color: '#808080', fontWeight: 400 }} to="/instructors">
                      Instructors
                    </Link>
                    <Typography
                      variant="body1"
                      sx={{
                        fontWeight: 400,
                        color: `${theme.palette.primary.main}!important`,
                        fontSize: { xs: '16px !important', md: '16px !important' },
                      }}
                    >
                      {instructorName}
                    </Typography>
                  </Breadcrumbs>
                )}
              </Box>
              <Typography
                variant="h2"
                sx={{
                  mb: 1,
                  textAlign: { xs: 'center', md: 'left' },
                  fontSize: { xs: '30px', md: '48px' },
                  fontWeight: '600',
                  color: '#333',
                }}
              >
                {instructorName}
              </Typography>
              <Box sx={{ display: { xs: 'flex', md: 'none' }, mt: 4, alignItems: 'center', position: 'relative' }}>
                <Box
                  sx={{
                    position: 'absolute',
                    zIndex: 0,
                    background: alpha(theme.palette.primary.main, 0.1),
                    width: '100%',
                    height: '100%',
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: '50%',
                    borderRadius: '30px 0 0 30px',
                  }}
                />
                <Card sx={{ pt: 3, pb: 3, background: 'transparent', position: 'relative', zIndex: 1, boxShadow: 0 }}>
                  {instructor?.profilePicture?.landscape && (
                    <CardMedia component="img" image={instructor.profilePicture.landscape} sx={{ borderRadius: 7 }} />
                  )}
                </Card>
              </Box>
              <Box>
                {instructor.role && (
                  <Typography
                    variant="body1"
                    sx={{
                      mb: 2,
                      color: theme.palette.primary.main,
                    }}
                  >
                    <span dangerouslySetInnerHTML={{ __html: instructor.role }} />
                  </Typography>
                )}

                <Stack direction="row" spacing={1}>
                  <Location fontSize={25} color={theme.palette.primary.main} />

                  <Typography
                    variant="body2"
                    sx={{
                      mb: 3,
                      color: darken(theme.palette.common.white, 0.799), //charcol color
                      lineHeight: 1.8,
                    }}
                  >
                    {instructor.location}
                  </Typography>
                </Stack>

                <Divider sx={{ mb: 3, mt: 2.5 }} />

                <Typography
                  variant="body2"
                  sx={{
                    color: darken(theme.palette.common.white, 0.799),
                    lineHeight: 1.8,
                  }}
                >
                  {instructor.bio && (
                    <span
                      dangerouslySetInnerHTML={{
                        __html: instructor.bio,
                      }}
                    />
                  )}
                </Typography>
                {orderedSocialLinks && orderedSocialLinks.length > 0 && (
                  <Grid container spacing={2} alignItems="center" mt={3}>
                    {orderedSocialLinks.map((item, index) => {
                      if (!item?.url) {
                        return null;
                      }
                      return (
                        <Grid key={index} item>
                          <MuiLink key={index} color="primary" href={item?.url} target="_blank">
                            {item.mediaType === instagram && <Instagram style={{ fontSize: '28px' }} />}
                            {item.mediaType === facebook && <Facebook style={{ fontSize: '28px' }} />}
                            {item.mediaType === linkedin && <Linkedin style={{ fontSize: '28px' }} />}
                            {item.mediaType === twitter && <PiTwitterLogo style={{ fontSize: '28px' }} />}
                            {item.mediaType === tiktok && <PiTiktokLogo style={{ fontSize: '28px' }} />}
                            {item.mediaType === youtube && <PiYoutubeLogo style={{ fontSize: '32px' }} />}
                            {item.mediaType === website && <Website style={{ fontSize: '28px' }} />}
                          </MuiLink>
                        </Grid>
                      );
                    })}
                  </Grid>
                )}
              </Box>
            </Grid>
            <Grid item md={6} sx={{ position: 'relative', display: { xs: 'none', md: 'block' } }}>
              <Box
                sx={{
                  position: 'absolute',
                  zIndex: 0,
                  background: '#FAE7E9',
                  width: '100%',
                  height: '100%',
                  top: 0,
                  right: 0,
                  bottom: 0,
                  left: '50%',
                }}
              />
              <Card
                sx={{
                  pt: 14,
                  pb: 15,
                  background: 'transparent',
                  position: 'relative',
                  zIndex: 1,
                  boxShadow: 0,
                  width: { xs: '390px', md: '770px' },
                  height: { xs: '228px', md: '550px' },
                }}
              >
                {instructor?.profilePicture?.landscape && (
                  <CardMedia
                    component="img"
                    image={instructor.profilePicture.landscape}
                    sx={{ borderRadius: 7, height: '100%', width: '100%' }}
                  />
                )}
              </Card>
            </Grid>
          </Grid>
        </Container>
        {isLogIn && instructor.strongPoints && instructor.strongPoints.length > 0 && (
          <Box sx={{ background: { xs: alpha(theme.palette.primary.light, 0.12), md: theme.palette.primary.main } }}>
            <Container maxWidth="xl">
              <Box
                sx={{
                  display: 'flex',
                  alignItems: { xs: 'flex-start', md: 'center' },
                  justifyContent: 'space-between',
                  flexDirection: { xs: 'column', md: 'row' },
                  pt: { xs: 2, md: 6 },
                  pb: { xs: 2, md: 6 },
                }}
              >
                {instructor.strongPoints.slice(0, 3).map((point, index) => (
                  <Typography sx={pointStyle} key={index}>
                    {point}
                  </Typography>
                ))}
              </Box>
            </Container>
          </Box>
        )}
        {!isLogIn && instructor.strongPoints && instructor.strongPoints.length > 0 && (
          <Box
            sx={{
              height: { xs: '195px', md: '106px' },
              background: `linear-gradient(170.53deg, ${theme.palette.primary.main} 33.19%, #FF9AA4 88.05%);`,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Container maxWidth="xl">
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: { xs: 'column', md: 'row' },
                  py: { xs: 2, md: 2 },
                  gap: { xs: 4, md: 4 },
                }}
              >
                <Typography
                  sx={{ color: 'white', textAlign: 'center', fontSize: { xs: '18px', md: '24px' }, fontWeight: 700 }}
                >
                  {instructor.strongPoints.join(', ')}
                </Typography>
                <Button
                  href={subscriptionLink}
                  sx={{ width: { xs: 220, md: 280 } }}
                  endIcon={
                    <Box
                      sx={{
                        background: theme.palette.primary.main,
                        borderRadius: '100%',
                        padding: '14px',
                        lineHeight: 0,
                      }}
                    >
                      <SlArrowRight
                        style={{
                          height: '17px',
                          width: '17px',
                        }}
                      />
                    </Box>
                  }
                >
                  Get My 7 Days Free
                </Button>
              </Box>
            </Container>
          </Box>
        )}
        <Box sx={{ background: theme.palette.grey[100], py: { xs: 4, md: 0 } }}>
          <Container maxWidth="xl">
            <Typography
              variant="h2"
              sx={{
                mb: 3,
                pt: { xs: 0, md: '80px' },
                textAlign: 'center',
                fontSize: { xs: '30px', md: '48px' },
                fontWeight: '600',
              }}
            >
              {instructor.firstName}'s Classes
            </Typography>
            {instructorContent?.items.length ? (
              <RelatedPosts
                contents={instructorContent.items}
                sx={{ background: 'transparent' }}
                actionButton={{
                  children: isDesktop ? `See All ${instructor.firstName}’s Classes` : 'See All Classes',
                  href: isLogIn
                    ? `/classes/by-teachers?instructorIds="${instructor.id}"`
                    : `/fitness-membership/${instructor.slug}/all-class`,
                }}
                sliderSettings={SliderSettings}
                {...(!isLogIn && {
                  subscriptionButtonLink: subscriptionLink,
                  subscriptionButtonLabel: 'Get My 7 Days Free',
                })}
              />
            ) : (
              <NotFoundCard entity="videos" sx={{ mb: 10 }} />
            )}
          </Container>
        </Box>
        {!isLogIn && (
          <InstructorSaleBanner
            title="Thoughtful fitness. Variety galore. </br>Community feeling."
            slug={instructor.slug ?? ''}
            affiliateId={instructor.affiliateId}
          />
        )}
      </Box>
    </>
  );
};
