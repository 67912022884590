import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import List from '@mui/material/List';
import Stack from '@mui/material/Stack';
import { TMovePlaylistContentRequestSchema, TPlaylist, TDraggableItem } from '@onesource/schemas';
import { useGetAllPlaylistsQuery } from '@onesource/services';
import { FC, FormEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { FaFloppyDisk } from 'react-icons/fa6';
import { LoadingError } from '../../loading-error';
import { DialogTitle } from '../dialog-title';
import { PlaylistItemForSelection } from './playlist-item';
import Typography from '@mui/material/Typography';
import { alpha } from '@mui/material';

export type TMovePlaylistItemContext = {
  item: TDraggableItem;
  playlist: TPlaylist;
};

export type TMovePlaylistItemDialog = {
  onClose: () => void;
  onSave: (params: TMovePlaylistContentRequestSchema) => void;
  isPending?: boolean;
  contextPlaylistItem: TDraggableItem | null;
};

export const MovePlaylistItemDialog: FC<TMovePlaylistItemDialog> = props => {
  const { onClose, onSave, isPending, contextPlaylistItem } = props;
  const [targetPlaylist, setTargetPlaylist] = useState<null | TPlaylist>(null);
  const {
    data: playlistsQueryRes,
    isLoading: isPlaylistsLoading,
    error: playlistsError,
    refetch: refetchPlaylistsQuery,
  } = useGetAllPlaylistsQuery();

  const currentPlaylist = useMemo(
    () => playlistsQueryRes?.items.find(playlist => playlist.items.some(item => item.id === contextPlaylistItem?.id)),
    [playlistsQueryRes, contextPlaylistItem],
  );

  const playlists: TPlaylist[] = useMemo(() => {
    return playlistsQueryRes?.items.filter(item => currentPlaylist?.id !== item.id) || [];
  }, [playlistsQueryRes?.items, currentPlaylist]);

  const onSetTargePlaylistHandler = useCallback((playlist: TPlaylist) => {
    setTargetPlaylist(playlist);
  }, []);

  const onCloseHandler = useCallback(() => {
    setTargetPlaylist(null);
    onClose();
  }, [onClose]);

  const onSubmitHandler = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      if (!targetPlaylist || !contextPlaylistItem || !currentPlaylist) {
        throw new Error('Invalid target playlist or context playlist item');
      }

      onSave({
        currentPlaylistId: Number(currentPlaylist.id),
        newPlaylistId: Number(targetPlaylist.id),
        itemId: Number(contextPlaylistItem.id),
      });
    },
    [targetPlaylist, contextPlaylistItem, currentPlaylist, onSave],
  );

  const onSaveHandler = useCallback(() => {
    if (!targetPlaylist || !contextPlaylistItem || !currentPlaylist) {
      throw new Error('Invalid target playlist or context playlist item');
    }

    onSave({
      currentPlaylistId: Number(currentPlaylist.id),
      newPlaylistId: Number(targetPlaylist.id),
      itemId: Number(contextPlaylistItem.id),
    });
  }, [targetPlaylist, contextPlaylistItem, currentPlaylist, onSave]);

  useEffect(() => {
    if (contextPlaylistItem) {
      return;
    }

    setTargetPlaylist(null);
  }, [contextPlaylistItem]);

  return (
    <Dialog open={!!contextPlaylistItem} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Select Playlist to move content</DialogTitle>
      <DialogContent>
        <form onSubmit={onSubmitHandler}>
          {/* <Select
            sx={{ mt: 2 }}
            name="playlistId"
            selected={playlistId || ''}
            label="Playlists"
            onChange={onChangePlaylistHandler}
            items={playlistsOptions}
          /> */}

          {isPlaylistsLoading && (
            <Stack alignItems="center" height="200px" justifyContent="center">
              <CircularProgress size={70} thickness={1} />
            </Stack>
          )}

          {playlistsError && (
            <LoadingError
              sx={{ width: '100%' }}
              entity="Class"
              message={playlistsError.message}
              refetch={refetchPlaylistsQuery}
              errorCode={playlistsError.code}
            />
          )}

          {Boolean(playlists.length) ? (
            <List sx={{ maxHeight: 300, overflowY: 'auto' }}>
              {playlists.map(item => (
                <PlaylistItemForSelection
                  key={item.id}
                  selected={targetPlaylist?.id === item.id}
                  onSetTargePlaylistHandler={onSetTargePlaylistHandler}
                  playlist={item}
                  targetPlaylist={targetPlaylist}
                />
              ))}
            </List>
          ) : (
            <Typography
              sx={{
                mt: 2,
                textAlign: 'center',
              }}
            >
              No playlists available, please create one
            </Typography>
          )}
        </form>
      </DialogContent>
      <DialogActions sx={{ p: 2 }}>
        <Button
          variant="outlined"
          sx={{
            minWidth: 'auto',
            color: 'primary.main',
            borderColor: 'primary.main',
            py: 1.1,
            px: 2.7,
          }}
          onClick={onCloseHandler}
        >
          Cancel
        </Button>

        <Button
          disabled={!targetPlaylist}
          startIcon={<FaFloppyDisk />}
          sx={{
            minWidth: 'auto',
            py: 1.2,
            px: 2.8,
          }}
          onClick={onSaveHandler}
        >
          Move Content
        </Button>
      </DialogActions>

      {isPending && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            background: theme => alpha(theme.palette.common.black, 0.5),
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </Dialog>
  );
};
