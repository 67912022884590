import { Cardio, Restore, Strength, YogaPose } from '@onesource/icons';
import { FC, useCallback, useMemo } from 'react';
import { FeaturedNav } from '../components/featured-nav';
import { InstructorSection } from '../components/instructor-section';
import { MostViewed } from '../components/most-viewed';
import { FocusedFitnessSection } from '../components/sections/focused-fitness';
import { Leaderboard } from '../components/sections/leaderboard';
import { RecentlyAdded } from '../components/sections/recently-added';
import { useTheme } from '@mui/material';
import { OneSourceSlideShow, cleanedFilters } from '@onesource/components';
import { EAppNameEnum, contentFilterDefaultValues } from '@onesource/schemas';
import { useRouter } from '@tanstack/react-router';
import { useGetContentsForTodayQuery } from '@onesource/services';
import { MonthChallenge } from '../components/sections/months-challange';
import { SeriesFeature } from 'components';

export const Home: FC = () => {
  const theme = useTheme();
  const { navigate } = useRouter();

  const { data: classForToday } = useGetContentsForTodayQuery(EAppNameEnum.FIT);

  const contentForTodayMemo = useMemo(() => {
    if (!classForToday?.items?.length) {
      return null;
    }

    return classForToday?.items?.[0];
  }, [classForToday]);

  const onClickCategoryHandler = useCallback(
    (categoryId: string) => {
      const cleaned = cleanedFilters({ ...contentFilterDefaultValues, categoryId });
      navigate({
        to: '/classes/by-category',
        search: cleaned,
      });
    },
    [navigate],
  );

  return (
    <>
      <OneSourceSlideShow app="fit" />

      <FeaturedNav
        sx={{ mt: { xs: 1, md: 7 } }}
        sectionTitle="Workout. Feel Good. Repeat."
        sectionSubtitle="Give your body the movement it craves."
        nav={[
          {
            name: 'STRENGTH',
            selected: false,
            onClick: onClickCategoryHandler,
            startIcon: <Strength color={theme.palette.grey[800]} width="42px" height="42px" />,
          },
          {
            name: 'FLOW',
            selected: false,
            onClick: onClickCategoryHandler,
            startIcon: <YogaPose color={theme.palette.grey[800]} width="42px" height="42px" />,
          },
          {
            name: 'CARDIO',
            selected: false,
            onClick: onClickCategoryHandler,
            startIcon: <Cardio color={theme.palette.grey[800]} width="42px" height="42px" />,
          },
          {
            name: 'RESTORE',
            selected: false,
            onClick: onClickCategoryHandler,
            startIcon: <Restore color={theme.palette.grey[800]} width="42px" height="42px" />,
          },
        ]}
      />

      {contentForTodayMemo && (
        <MonthChallenge
          title={contentForTodayMemo?.settings?.values.title ?? contentForTodayMemo.content.name}
          category={contentForTodayMemo.content.category}
          subtitle={
            contentForTodayMemo?.settings?.values.subtitle ??
            `${contentForTodayMemo.content.instructor.firstName} ${contentForTodayMemo.content.instructor.lastName}`
          }
          description={contentForTodayMemo?.settings?.values.body ?? contentForTodayMemo.content.video.description}
          buttonLabel={contentForTodayMemo?.settings?.values.button_label ?? 'Show all'}
          buttonLink={'/classes/by-date'}
          videoBadge={contentForTodayMemo?.settings?.values.video_badge ?? "Today's class"}
          cardMedia={{
            image: contentForTodayMemo.content.video.bannerUrl2x || contentForTodayMemo.content.video.bannerUrl || '',
            sx: {
              borderRadius: '30px 0 30px 30px',
            },
          }}
        />
      )}

      <SeriesFeature />

      <InstructorSection
        title="All the Ways You Love to Move"
        subtitle="Guided by our expert instructors"
        buttonLabel="Meet All"
        buttonLink="/instructors"
      />

      <RecentlyAdded title="New to the Platform" buttonLabel="See All" buttonLink="/classes/all" />

      <FocusedFitnessSection
        title="Curated Collections"
        description="Each collection is curated to support your unique FITness goals."
        buttonLink="/series"
        buttonLabel="See All"
      />
      <MostViewed sectionTitle="Most Popular" />
      {/* <MostWatched sectionTitle="Most Viewed" /> */}
      {/* <OnlineMembersSection sectionTitle="Who's Online" /> */}
      <Leaderboard title="Leaderboard" />
    </>
  );
};
