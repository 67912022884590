import { FC } from 'react';
import { InstructorDetailsBody } from '../components/sections/single-instructor-details';
import { useInstructorDetailsPageParams } from 'routes/instructors/$instructorId';
import { useGetContentsQuery, useGetInstructorQuery } from '@onesource/services';
import { EAppNameEnum } from '@onesource/schemas';
import { InstructorDetailsLoading } from 'components/sections/single-instructor-details/loading';
import { LoadingError } from '@onesource/components';
import Container from '@mui/material/Container';

export const InstructorDetails: FC = () => {
  const { instructorId } = useInstructorDetailsPageParams();
  const { data: instructor, isLoading, error, refetch } = useGetInstructorQuery({ instructorId });

  const {
    data: instructorContent,
    isLoading: isContentLoading,
    error: contentError,
  } = useGetContentsQuery({
    app: EAppNameEnum.FIT,
    filters: { instructorIds: instructorId },
  });

  if (isLoading || isContentLoading) {
    return <InstructorDetailsLoading />;
  }

  if (error) {
    return (
      <Container maxWidth="xl">
        <LoadingError
          sx={{ width: '100%', my: 4 }}
          entity="Instructor"
          message={error.message}
          refetch={refetch}
          errorCode={error.code}
        />
      </Container>
    );
  }

  if (contentError) {
    return (
      <Container maxWidth="xl">
        <LoadingError
          sx={{ width: '100%', my: 4 }}
          entity="Instructor Content"
          message={contentError.message}
          refetch={refetch}
          errorCode={contentError.code}
        />
      </Container>
    );
  }
  if (!instructorId || !instructorContent || !instructor) {
    return null;
  }

  return <InstructorDetailsBody instructorContent={instructorContent} instructor={instructor} />;
};
