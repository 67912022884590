import Box from '@mui/material/Box';
import { alpha, SxProps, useTheme } from '@mui/material/styles';
import { Cardio, Restore, Strength, YogaPose } from '@onesource/icons';
import { CategoryEnum, TCategory } from '@onesource/schemas';
import { FC } from 'react';

export interface IContentCategoryBadgeProps {
  category: TCategory;
  sx?: SxProps;
  color?: 'primary' | 'secondary';
  size?: 'small' | 'medium' | 'large';
}

export const ContentCategoryBadge: FC<IContentCategoryBadgeProps> = props => {
  const { size = 'large', category, sx, color = 'primary' } = props;
  const theme = useTheme();

  const sizes = {
    large: {
      badgeSize: 50,
      iconSize: 32,
    },
    medium: {
      badgeSize: 40,
      iconSize: 24,
    },
    small: {
      badgeSize: 25,
      iconSize: 18,
    },
  };

  return (
    <Box
      sx={{
        width: sizes[size].badgeSize,
        height: sizes[size].badgeSize,
        borderRadius: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: theme => theme.palette[color].main,
        boxShadow: theme => `0px 4px 5px 0px ${alpha(theme.palette.common.black, 0.2)};`,
        ...sx,
      }}
    >
      {category === CategoryEnum.STRENGTH && (
        <Strength fontSize={sizes[size].iconSize} color={theme.palette.common.white} />
      )}
      {category === CategoryEnum.CARDIO && (
        <Cardio fontSize={sizes[size].iconSize} color={theme.palette.common.white} />
      )}
      {category === CategoryEnum.FLOW && (
        <YogaPose fontSize={sizes[size].iconSize} color={theme.palette.common.white} />
      )}
      {category === CategoryEnum.RESTORE && (
        <Restore fontSize={sizes[size].iconSize} color={theme.palette.common.white} />
      )}
    </Box>
  );
};
