import CardActions from '@mui/material/CardActions';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import { alpha, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Clock, MvPlay } from '@onesource/icons';
import { IndicatorEnum, TContent } from '@onesource/schemas';
import { ellipsis } from '@onesource/utils';
import { Link } from '@tanstack/react-router';
import { FC } from 'react';
import { HiDotsVertical } from 'react-icons/hi';
import { BiMoveVertical } from 'react-icons/bi';
import { motion } from 'framer-motion';
import { useGetByDevice } from '../../hooks';
import { RequirementIndicator } from '../../requirement-indicator';
import { ContentCategoryBadge } from '../../content-category-badge';

type IProps = {
  itemId: string;
  enableDrag?: boolean;
  content: TContent;
  isDashboard?: boolean;
  isActionMethod?: boolean;
  isContextMenuShow?: boolean;
  isActionDisabled?: boolean;
  onClickContextMenuHandler: (event: React.MouseEvent<HTMLButtonElement>, itemId: string) => void;
};

export const DraggableContentMobileViewCard: FC<IProps> = props => {
  const {
    content,
    enableDrag,
    onClickContextMenuHandler,
    itemId,
    isDashboard,
    isActionMethod,
    isContextMenuShow,
    isActionDisabled,
  } = props;
  const { id, category, equipments, averageRating, instructor, name } = content;
  const theme = useTheme();
  const { isTablet, isXsMobile } = useGetByDevice();

  const avatarSize = enableDrag ? (isTablet ? 170 : isXsMobile ? 70 : 85) : isTablet ? 170 : 100;

  return (
    <Card
      sx={{
        borderRadius: enableDrag ? `4px 16px 16px 4px` : 3,
        boxShadow: '0px 0px 20px 10px rgba(51, 51, 51, 0.05)',
        backgroundColor: enableDrag ? alpha(theme.palette.primary.main, 0.1) : theme.palette.common.white,
        transition: 'background-color 0.3s',
        '&:hover': {
          backgroundColor: !enableDrag ? alpha(theme.palette.primary.main, 0.2) : theme.palette.grey[100],
        },
      }}
    >
      <CardContent sx={{ p: isTablet ? 3 : 1 }}>
        <Grid container>
          {enableDrag && (
            <Grid item xs={1} sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
              <motion.div
                initial={{ opacity: 0.8, scale: 0.8 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{
                  repeat: Infinity,
                  duration: 1,
                  ease: 'easeInOut',
                  repeatType: 'reverse',
                }}
              >
                <BiMoveVertical color={theme.palette.primary.main} size={33} />
              </motion.div>
            </Grid>
          )}

          <Grid item xs={enableDrag ? 3 : 4} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <CardMedia
              component="img"
              sx={{
                width: avatarSize,
                height: avatarSize,
                borderRadius: '100%',
              }}
              image={content.video.bannerUrl}
            />
          </Grid>
          <Grid item xs={8}>
            <Box>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Box>
                  <ContentCategoryBadge
                    size={isTablet ? 'large' : 'medium'}
                    category={category}
                    sx={{ boxShadow: 0 }}
                  />
                </Box>
                <Box sx={{ display: 'flex' }}>
                  {!isDashboard && (
                    <Link
                      to={`/classes/details/${id}`}
                      style={{
                        pointerEvents: enableDrag ? 'none' : 'auto',
                        textDecoration: 'none',
                      }}
                    >
                      <IconButton
                        size={isTablet ? 'large' : 'medium'}
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          background: theme => (enableDrag ? theme.palette.grey[300] : theme.palette.primary.main),
                          color: theme => (enableDrag ? theme.palette.grey[400] : theme.palette.common.white),

                          boxShadow: enableDrag ? 'none' : `5px 5px 15px ${theme.palette.common.black}20`,
                          '&:hover': {
                            background: theme => theme.palette.primary.dark,
                          },
                        }}
                      >
                        <MvPlay fontSize={isTablet ? 27 : 22} />
                      </IconButton>
                    </Link>
                  )}
                  {(isContextMenuShow || isActionMethod) && (
                    <IconButton
                      disabled={enableDrag || isActionDisabled}
                      onClick={event => onClickContextMenuHandler(event, itemId)}
                      size={isTablet ? 'large' : 'medium'}
                      sx={{
                        ml: 1.5,
                        background: theme.palette.primary.main,
                        color: theme.palette.common.white,
                        boxShadow: `5px 5px 15px ${theme.palette.common.black}20`,
                        '&:hover': {
                          background: theme.palette.primary.dark,
                        },
                      }}
                    >
                      <HiDotsVertical fontSize={isTablet ? 27 : 22} />
                    </IconButton>
                  )}
                </Box>
              </Box>
              <Box sx={{ py: { xs: 1, sm: 2 }, px: 0.5 }}>
                <Typography gutterBottom variant={isTablet ? 'h5' : 'body1'} sx={{ ...ellipsis, fontWeight: 500 }}>
                  {name.substring(0, 55)}
                </Typography>

                <Typography variant={isTablet ? 'body1' : 'body2'} color="text.secondary">
                  {instructor.firstName} {instructor.lastName}
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions
        sx={{
          px: isTablet ? 3 : 1,
        }}
      >
        <Box
          sx={{
            width: '100%',
          }}
        >
          <Grid container spacing={{ xs: 0.7, sm: 1.5 }}>
            <Grid item>
              <Chip
                label={`${content.video.duration} m`}
                sx={{
                  p: 0.2,
                  backgroundColor: theme => theme.palette.grey[800],
                  color: 'white',
                }}
                size={isTablet ? 'medium' : 'small'}
                icon={<Clock fontSize={18} color={theme.palette.common.white} />}
              />
            </Grid>

            {equipments.map(item => (
              <Grid item key={item.slug}>
                <Chip
                  key={item.slug}
                  label={item.name}
                  sx={{
                    p: 0.2,
                    backgroundColor: theme => theme.palette.grey[800],
                    color: 'white',
                  }}
                  size={isTablet ? 'medium' : 'small'}
                />
              </Grid>
            ))}

            <Grid item xs={isTablet ? undefined : 12} sx={{ ml: 'auto' }}>
              <Stack
                direction="row"
                spacing={2}
                sx={{
                  my: isTablet ? undefined : { xs: 1, sm: 2 },
                }}
              >
                <RequirementIndicator
                  indicator={IndicatorEnum.difficulty}
                  point={averageRating.difficulty}
                  isInteractive={false}
                  fontSize={20}
                />

                <RequirementIndicator
                  indicator={IndicatorEnum.intensity}
                  point={averageRating.intensity}
                  isInteractive={false}
                  fontSize={20}
                />

                <RequirementIndicator
                  indicator={IndicatorEnum.difficulty}
                  point={averageRating.nerdiness}
                  isInteractive={false}
                  fontSize={20}
                />
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </CardActions>
    </Card>
  );
};
