import { alpha, darken, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { LoadingError, MovePlaylistItemDialog, NotFoundCard, Reorder, useGetByDevice } from '@onesource/components';
import { TDeleteContentFromPlaylistRequestSchema, TDraggableItem } from '@onesource/schemas';
import { useGetPlaylistQuery, useUpdatePlaylistItemsOrderMutation } from '@onesource/services';
import { Link } from '@tanstack/react-router';
import { PageHeader } from 'components/page-header';
import { usePlaylistAction } from 'hooks/use-playlist-actions';
import { FC, useCallback, useEffect, useState } from 'react';
import { AiOutlineDrag } from 'react-icons/ai';
import { FaPlayCircle } from 'react-icons/fa';
import { FaFloppyDisk } from 'react-icons/fa6';
import { IoCloseSharp } from 'react-icons/io5';
import { usePlaylistIdParams } from 'routes/_protected/playlists/$playlistId';
import { PlaylistDetailsLoading } from './loading';

export const PlaylistDetails: FC = () => {
  const { playlistId } = usePlaylistIdParams();
  const theme = useTheme();

  const { isMobile } = useGetByDevice();

  const {
    data: playlistQueryRes,
    isLoading: playlistQueryLoading,
    error: playlistQueryError,
    refetch: refetchPlaylistQueryError,
  } = useGetPlaylistQuery(playlistId);

  const {
    isMovePending,
    onCloseMovePlaylistItemDialog,
    movePlaylistItemHandler,
    movePlaylistItemContext,
    removePlaylistItem,
    openMovePlaylistItemDialogHandler,
  } = usePlaylistAction(playlistQueryRes);

  const [enableOrdering, setEnableOrdering] = useState(false);
  const [contents, setSortedItems] = useState<TDraggableItem[]>([]);
  const [isDirty, setIsDirty] = useState(false);
  const { mutateAsync: reorderPlaylistItems, isPending: isPendingReorder } = useUpdatePlaylistItemsOrderMutation();

  const totalVideos = playlistQueryRes?.items?.length || 0;
  const videoLength = playlistQueryRes?.items?.reduce((acc, curr) => acc + Number(curr.content.video.duration), 0) || 0;
  const hours = Math.floor(videoLength / 60);
  const minutes = videoLength % 60;
  const isContentAvailable = !!playlistQueryRes?.items.length;

  useEffect(() => {
    setSortedItems(playlistQueryRes?.items || []);
  }, [playlistQueryRes?.items]);

  const handleEnableReorder = useCallback(() => {
    setEnableOrdering(prev => !prev);
  }, []);

  const saveContentOrderHandler = useCallback(async () => {
    const contentWithOrder = contents.map((content, index) => ({
      ...content,
      order: index + 1,
    }));

    const payload = {
      id: playlistId,
      items: contentWithOrder.map(item => {
        const { id, order } = item;
        return {
          id,
          order,
          content: { id: id.toString() },
        };
      }),
    };

    await reorderPlaylistItems(payload, {
      onSuccess: () => {
        setIsDirty(false);
        setEnableOrdering(false);
      },
    });
  }, [contents, playlistId, reorderPlaylistItems]);

  const onChangeOrderHandler = useCallback(() => {
    setIsDirty(true);
  }, []);

  const onClickRemovePlaylistItemHandler = useCallback(
    (params: TDeleteContentFromPlaylistRequestSchema) => {
      removePlaylistItem(params);
    },
    [removePlaylistItem],
  );

  if (playlistQueryLoading) {
    return <PlaylistDetailsLoading />;
  }

  if (playlistQueryError) {
    return (
      <Box sx={{ background: alpha(theme.palette.grey[100], 0.4) }}>
        <Container maxWidth="xl" sx={{ mt: 0, pt: 8, pb: 8 }}>
          <LoadingError
            sx={{ width: '100%' }}
            entity="Class"
            message={playlistQueryError.message}
            refetch={refetchPlaylistQueryError}
            errorCode={playlistQueryError.code}
          />
        </Container>
      </Box>
    );
  }

  return (
    <Box sx={{ background: theme => theme.palette.common.white, overflow: 'hidden' }}>
      <PageHeader title="Playlist" />

      <Container maxWidth="xl" sx={{ position: 'relative', py: { xs: 4, md: 8 } }}>
        <Grid container spacing={2} mb={{ xs: 6, md: 12 }}>
          <Grid item flexGrow={1} xs={12}>
            <Box
              sx={{ display: 'flex', alignItems: 'center', justifyContent: { xs: 'space-between', md: 'flex-start' } }}
            >
              <Typography
                variant="h2"
                sx={{
                  color: darken(theme.palette.common.white, 0.799),
                  '&:after': {
                    content: '" "',
                    display: 'block',
                    height: '4px',
                    width: '25px',
                    background: theme.palette.primary.main,
                  },
                }}
              >
                {playlistQueryRes?.name}
              </Typography>

              <Link
                to={`/playlists/${playlistId}/play`}
                search={{
                  playlistId,
                }}
                style={{ display: 'inline-block', pointerEvents: isContentAvailable ? 'auto' : 'none' }}
              >
                <IconButton
                  disabled={!isContentAvailable}
                  size="large"
                  sx={{
                    p: 0,
                    ml: 3,
                    color: theme.palette.primary.main,
                    transition: 'color 0.3s',
                    '&:hover': {
                      color: theme.palette.primary.dark,
                    },
                  }}
                >
                  <FaPlayCircle size={isMobile ? 40 : 50} />
                </IconButton>
              </Link>
            </Box>

            <Stack direction="row" alignItems="center" mt={3} mb={2}>
              <Typography variant={isMobile ? 'body2' : 'h5'} fontWeight={isMobile ? '600' : '700'}>
                {totalVideos} videos
              </Typography>

              <Box
                sx={{
                  borderRight: `1px solid ${theme.palette.grey[500]}`,
                  height: '20px',
                  display: 'inline-block',
                  position: 'relative',
                  top: '2px',
                  mx: { xs: 1.5, md: 3 },
                }}
              />

              <Typography
                variant={isMobile ? 'body2' : 'h5'}
                fontWeight={isMobile ? '600' : '700'}
                color="primary.main"
              >
                {hours}h {minutes}m playtime
              </Typography>
            </Stack>
          </Grid>

          {isContentAvailable && (
            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              {!enableOrdering ? (
                <Button
                  disabled={isPendingReorder}
                  onClick={handleEnableReorder}
                  size={isMobile ? 'small' : 'large'}
                  variant="contained"
                  color="primary"
                  sx={{ minWidth: 'auto', p: { xs: '7px 15px', md: '10px 20px' } }}
                  startIcon={<AiOutlineDrag />}
                >
                  Reorder
                </Button>
              ) : (
                <Stack direction="row" spacing={2}>
                  <Button
                    disabled={isPendingReorder || !isDirty}
                    onClick={saveContentOrderHandler}
                    size={isMobile ? 'small' : 'large'}
                    variant="contained"
                    color="primary"
                    sx={{ minWidth: 'auto', p: { xs: '7px 15px', md: '10px 20px' } }}
                    startIcon={<FaFloppyDisk />}
                  >
                    Save Order
                  </Button>

                  <Button
                    onClick={handleEnableReorder}
                    size={isMobile ? 'small' : 'large'}
                    variant="outlined"
                    color="error"
                    sx={{ minWidth: 'auto', p: { xs: '7px 15px', md: '10px 20px' }, color: theme.palette.error.main }}
                    startIcon={<IoCloseSharp />}
                  >
                    Cancel
                  </Button>
                </Stack>
              )}
            </Grid>
          )}
        </Grid>

        {!isContentAvailable && <NotFoundCard entity="Videos" />}

        {isContentAvailable && (
          <Reorder
            contents={contents}
            setSortedItems={setSortedItems}
            onRemove={onClickRemovePlaylistItemHandler}
            onMove={openMovePlaylistItemDialogHandler}
            enableOrdering={enableOrdering}
            onChangeOrder={onChangeOrderHandler}
            playlistId={playlistId}
          />
        )}

        {isPendingReorder && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'absolute',
              borderRadius: 4,
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              zIndex: 9,
              background: alpha(theme.palette.common.black, 0.2),
            }}
          >
            <CircularProgress />
          </Box>
        )}
      </Container>

      <MovePlaylistItemDialog
        contextPlaylistItem={movePlaylistItemContext}
        onClose={onCloseMovePlaylistItemDialog}
        onSave={movePlaylistItemHandler}
        isPending={isMovePending}
      />
    </Box>
  );
};
