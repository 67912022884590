import { useTheme } from '@mui/material';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { TPlaylist } from '@onesource/schemas';
import { FC, useCallback } from 'react';
import { FaCheck } from 'react-icons/fa6';
import { PiFolderOpenLight } from 'react-icons/pi';

export type PlaylistItemForSelectionProps = {
  selected: boolean;
  playlist: TPlaylist;
  targetPlaylist: TPlaylist | null;
  onSetTargePlaylistHandler: (playlist: TPlaylist) => void;
};

export const PlaylistItemForSelection: FC<PlaylistItemForSelectionProps> = props => {
  const theme = useTheme();
  const { onSetTargePlaylistHandler, selected, playlist } = props;

  const changePlaylistHandler = useCallback(() => {
    onSetTargePlaylistHandler(playlist);
  }, [onSetTargePlaylistHandler, playlist]);

  return (
    <ListItemButton onClick={changePlaylistHandler} selected={selected}>
      <ListItemIcon
        sx={{
          minWidth: 'auto',
          mr: 2,
        }}
      >
        <PiFolderOpenLight color={theme.palette.primary.main} size={26} />
      </ListItemIcon>

      <ListItemText
        sx={{
          color: theme.palette.primary.main,
          fontWeight: 300,
          '& .MuiTypography-root': {
            fontWeight: 500,
          },
        }}
        primary={playlist.name}
      />
      {selected && (
        <ListItemIcon
          sx={{
            minWidth: 'auto',
          }}
        >
          <FaCheck
            color={theme.palette.success.main}
            size={17}
            style={{
              border: `2px solid ${theme.palette.success.main}`,
              borderRadius: '50%',
              padding: '4px',
            }}
          />
        </ListItemIcon>
      )}
    </ListItemButton>
  );
};
