import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { FC } from 'react';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { darken, useTheme } from '@mui/material';
import { WP_MEMBERS_SITE } from 'config';
import { InstructorPortraitContainer } from './instructor-portrait-container';
import { SlArrowRight } from 'react-icons/sl';

type TInstructorSaleBannerProps = {
  title: string;
  slug: string;
  affiliateId?: string;
};

export const InstructorSaleBanner: FC<TInstructorSaleBannerProps> = props => {
  const { title, slug, affiliateId } = props;
  const theme = useTheme();

  return (
    <Box
      sx={{
        width: '100%',
        height: { xs: '807px', md: '507px' },
        overflow: 'hidden',
        position: 'relative',
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: { xs: '100%', md: '100%' },
          backgroundImage: `url(/assets/background/sale-banner-bg.png)`,
          backgroundSize: { xs: 'cover', md: 'cover' },
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          transform: { xs: 'rotate(180deg)', md: 'rotate(0deg)' },
          transformOrigin: 'center',
          zIndex: -1,
        },
      }}
    >
      <Grid container sx={{ height: '100%' }}>
        <Grid
          item
          xs={12}
          md={5}
          position="relative"
          sx={{
            zIndex: 1,
            '&:before': {
              content: "''",
              position: 'absolute',
              width: {
                xs: '900px',
                sm: '1200px',
                md: '900px',
                lg: '1200px',
              },
              height: {
                xs: '600px',
                sm: '1200px',
                md: '900px',
                lg: '1200px',
              },
              top: { xs: 100, sm: 80, md: '-160px', lg: -300 },
              bottom: { xs: -200, md: undefined },
              right: { xs: -245, sm: -200, md: 10, lg: 20 },
              background: 'linear-gradient(100.75deg, #FCD9DE 56.43%, rgba(255, 192, 192, 0.9) 86.68%)',
              borderRadius: { xs: '50%', md: '50%' },
            },
          }}
          display="flex"
          justifyContent={{ xs: 'center', md: 'flex-end' }}
          alignItems="end"
          order={{ xs: 2, md: 1 }}
        >
          <InstructorPortraitContainer slug={slug} />
        </Grid>
        <Grid
          item
          xs={12}
          md={7}
          order={{ xs: 1, md: 2 }}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
        >
          <Box>
            <Typography
              sx={{
                fontSize: { xs: '30px', md: '35px' },
                color: darken(theme.palette.common.white, 0.799),
                fontWeight: 500,
                textAlign: 'center',
              }}
            >
              <span dangerouslySetInnerHTML={{ __html: title }} />
            </Typography>
            <Typography
              sx={{
                fontWeight: 200,
                fontSize: { xs: '25px', md: '35px' },
                color: darken(theme.palette.common.white, 0.799),
                whiteSpace: 'pre-line',
                wordBreak: 'break-word',
                textAlign: 'center',
              }}
            >
              Sign up now!
            </Typography>
          </Box>

          <Stack direction="row" spacing={{ xs: 2, md: 5 }} justifyContent="center" sx={{ mt: '32px', px: 1 }}>
            <Box sx={{ textAlign: 'center' }}>
              <Typography
                sx={{
                  color: 'white',
                  fontSize: '24px',
                  fontWeight: '700',
                  textAlign: 'center',
                }}
              >
                Monthly
              </Typography>
              <Typography sx={{ fontWeight: '400', color: darken(theme.palette.common.white, 0.799) }}>
                <strong>$22 USD</strong> / Month
              </Typography>
              <Typography
                sx={{ fontWeight: '400', fontStyle: 'italic', color: darken(theme.palette.common.white, 0.799) }}
              >
                (Billed every month)
              </Typography>
            </Box>
            <Box sx={{ width: '2px', bgcolor: 'white', height: { xs: '80px', md: '90px' } }}></Box>
            <Box sx={{ textAlign: 'center' }}>
              <Typography
                sx={{
                  color: 'white',
                  fontSize: '24px',
                  fontWeight: '700',
                  textAlign: 'center',
                }}
              >
                Annual
              </Typography>
              <Stack direction="row" spacing={2}>
                <Typography
                  sx={{
                    position: 'relative',
                    display: 'inline-block',
                    fontSize: '18px',
                    fontWeight: '400',
                    color: darken(theme.palette.common.white, 0.799),
                    '&::after': {
                      content: '""',
                      position: 'absolute',
                      left: 0,
                      top: { xs: '30%', md: '50%' },
                      width: '100%',
                      height: '2px',
                      backgroundColor: theme.palette.primary.light,
                      transform: 'rotate(150deg)',
                      transformOrigin: 'center',
                    },
                  }}
                >
                  $264
                </Typography>

                <Typography sx={{ fontWeight: '400', color: darken(theme.palette.common.white, 0.799) }}>
                  <strong>$220 USD</strong> / Year
                </Typography>
              </Stack>
              <Typography
                sx={{ fontStyle: 'italic', fontWeight: '400', color: darken(theme.palette.common.white, 0.799) }}
              >
                Get 2 months FREE
              </Typography>
            </Box>
          </Stack>
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: { xs: '34px', md: '40px' } }}>
            <Button
              href={`${WP_MEMBERS_SITE}/payment/ryc-fit/${affiliateId ? `?rxf=${affiliateId}` : ''}`}
              endIcon={
                <Box
                  sx={{
                    background: theme.palette.primary.main,
                    borderRadius: '100%',
                    padding: '14px',
                    lineHeight: 0,
                  }}
                >
                  <SlArrowRight
                    style={{
                      height: '17px',
                      width: '17px',
                    }}
                  />
                </Box>
              }
            >
              Get My 7 Days Free
            </Button>
          </Box>
          <Typography
            sx={{
              mt: 1,
              mb: 2,
              fontWeight: 400,
              fontStyle: 'italic',
              fontSize: '11px',
              lineHeight: '20px',
              color: '#333333',
              textAlign: 'center',
            }}
          >
            *400+ on-demand classes
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};
