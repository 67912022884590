import { IPaginationNew, TAppNameEnum, TContent, TError } from '@onesource/schemas';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import axios from '../axios';
import { ENTITY } from './entity';

interface IGetContentsPublicParams {
  app: TAppNameEnum;
  instructorId: string;
}

export const getContentsPublicQueryKey = (instructorId: string) => {
  const keys = [ENTITY, instructorId];
  return keys;
};

export const useGetContentsPublicQuery = (param: IGetContentsPublicParams) => {
  const { instructorId, app } = param;

  return useQuery<IPaginationNew<TContent>, TError>({
    queryKey: getContentsPublicQueryKey(instructorId),
    queryFn: async () => {
      const res = await axios.get<IPaginationNew<TContent>>(`${ENTITY}/public`, {
        params: {
          app,
          instructorId,
        },
      });

      return res.data;
    },
    enabled: !!instructorId,
    refetchOnWindowFocus: false,
  });
};

export const useGetContentsQueryPublicCachedData = (instructorId: string) => {
  const qc = useQueryClient();
  const key = getContentsPublicQueryKey(instructorId);
  return qc.getQueryData<IPaginationNew<TContent>>(key);
};
