import { darken, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { DataStateHandler, InstructorCard, TeacherCardLoading } from '@onesource/components';
import { InstructorTypeEnum, TInstructor } from '@onesource/schemas';
import { useGetInstructorsQuery } from '@onesource/services';
import { FC } from 'react';

const LoadingComponent: FC = () => {
  return (
    <Grid container spacing={5}>
      {Array.from({ length: 12 }).map((_x, index) => (
        <Grid key={index} item lg={3} md={4} sm={6} xs={6}>
          <TeacherCardLoading />
        </Grid>
      ))}
    </Grid>
  );
};

export const AllInstructors: FC = () => {
  const theme = useTheme();
  const queryResult = useGetInstructorsQuery({
    filters: {
      type: InstructorTypeEnum.REGULAR,
    },
  });

  const guestQueryResult = useGetInstructorsQuery({
    filters: {
      type: InstructorTypeEnum.GUEST,
    },
  });

  return (
    <Box sx={{ background: theme => theme.palette.grey[100] }}>
      <Box
        sx={{
          borderRadius: { xs: '0px 0px 70px 70px', md: '0px 0px 150px 150px' },
          background: theme => theme.palette.common.white,
          pb: { xs: 5, md: 11 },
        }}
      >
        <Container maxWidth="xl">
          <Typography
            variant="body2"
            sx={{ pt: { xs: 3, md: 7 }, textAlign: 'center', color: darken(theme.palette.common.white, 0.799) }}
          >
            <strong> Our Instructors </strong> continue to offer fresh, new content regularly. With their ever-evolving
            expertise, you'll always have access to innovative and diverse classes that support your FITness journey.
          </Typography>

          <Grid container justifyContent="center" mt={5}>
            <DataStateHandler<TInstructor>
              queryResult={queryResult}
              entity="Instructor"
              gridProps={{
                lg: 3,
                md: 4,
                sm: 6,
                xs: 6,
              }}
              LoadingComponent={LoadingComponent}
              IterativeComponent={(props: { item: TInstructor }) => (
                <InstructorCard type="V-CARD" instructor={props.item} sx={{ cursor: 'pointer' }} />
              )}
            />
          </Grid>
        </Container>
      </Box>

      <Box sx={{ pb: { xs: 5, md: 11 } }}>
        <Typography
          variant="h2"
          sx={{ pt: 8, pb: 3, textAlign: 'center', color: darken(theme.palette.common.white, 0.799) }}
        >
          Guest Instructors
        </Typography>
        <Typography
          variant="body2"
          sx={{ pb: { xs: 3, md: 8 }, textAlign: 'center', color: darken(theme.palette.common.white, 0.799) }}
        >
          <strong> All of our Instructors </strong> are rigorously trained, highly experienced, and dedicated to
          providing you with top-quality movement guidance.
        </Typography>
        <Container maxWidth="xl">
          <Grid container>
            <DataStateHandler<TInstructor>
              queryResult={guestQueryResult}
              entity="Guest Instructor"
              gridProps={{
                lg: 4,
                md: 6,
                sm: 6,
                xs: 12,
              }}
              LoadingComponent={LoadingComponent}
              IterativeComponent={(props: { item: TInstructor }) => (
                <InstructorCard type="H-CARD" instructor={props.item} sx={{ cursor: 'pointer' }} />
              )}
            />
          </Grid>
        </Container>
      </Box>
    </Box>
  );
};
