import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import Typography from '@mui/material/Typography';
import { useDebounceCallback } from 'usehooks-ts';
import { FC, useCallback, useEffect, useState } from 'react';
import { Emptybars, Fullbars } from '@onesource/icons';
import { useGetByDevice } from '../../hooks';

interface DynamicFilterItemProps {
  value: [number, number];
  min: number;
  max: number;
  type: 'duration' | 'intensity';
  onChange: (value: [number, number]) => void;
  step?: number;
}

export const RangeSlider: FC<DynamicFilterItemProps> = props => {
  const { onChange, value, min = 0, max = 100, type, step = 1 } = props;
  const [localValue, setLocalValue] = useState<[number, number]>(value || [0, 0]);
  const debouncedCallback = useDebounceCallback(onChange, 600);
  const { isMobile } = useGetByDevice();

  const isDurationType = type === 'duration';
  const isIntensityType = type === 'intensity';

  const onChangeHandler = useCallback(
    (_e: Event, value: number | number[]) => {
      if (!Array.isArray(value)) {
        return [0, 0];
      }

      const start = value[0] || 0;
      const end = value[1] || 0;

      setLocalValue([start, end]);
      debouncedCallback([start, end]);
    },
    [debouncedCallback],
  );

  useEffect(() => {
    const start = value?.[0] || 0;
    const end = value?.[1] || 0;

    setLocalValue([start, end]);
  }, [value]);

  const barIconStyle = {
    height: '24px',
    width: '65px',
  };

  return (
    <Box
      sx={{
        display: 'flex',
        gap: 2,
        pb: { xs: 0, md: 1 },
        pt: { xs: 0.5, md: 0.7 },
        paddingRight: 0.4,
      }}
    >
      {isDurationType && (
        <Typography variant="body2" sx={{ marginRight: 0.5, width: '30px', display: 'flex', alignItems: 'center' }}>
          {localValue[0] || 0}m
        </Typography>
      )}

      {isIntensityType && <Emptybars style={barIconStyle} />}

      <Slider
        name="duration"
        value={localValue.map(val => Number(val))}
        onChange={onChangeHandler}
        min={min}
        max={max}
        valueLabelDisplay="auto"
        step={step}
        size={isMobile ? 'small' : 'medium'}
        sx={{
          pt: { xs: 1 },
          mx: 1,
        }}
      />

      {isDurationType && (
        <Typography
          variant="body2"
          sx={{
            marginLeft: 0.5,
            mr: 1.5,
            width: '30px',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {localValue[1] || 0}m
        </Typography>
      )}

      {isIntensityType && <Fullbars style={barIconStyle} />}
    </Box>
  );
};
