import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { isLoggedIn } from '@onesource/services';
import { useRouter } from '@tanstack/react-router';
import { FC, useEffect } from 'react';

export const AuthGuard: FC<{ children: JSX.Element | JSX.Element[] }> = props => {
  const { children } = props;
  const router = useRouter();

  useEffect(() => {
    if (!isLoggedIn()) {
      setTimeout(() => {
        router.navigate({
          to: '/auth/login',
        });
      }, 1000);
    }
  }, [router]);

  if (!isLoggedIn()) {
    return (
      <Box
        sx={{
          height: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
        }}
      >
        <LinearProgress sx={{ width: '400px', borderRadius: '10px' }} />
      </Box>
    );
  }

  return <>{children}</>;
};
