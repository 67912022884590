import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { ArrowRightIcon } from '@mui/x-date-pickers';
import { LockWithCircle } from '@onesource/icons';
import { FC, memo, useCallback, useState } from 'react';

type SubscribeCardProps = {
  subscriptionButtonLink?: string;
  subscriptionButtonLabel?: string;
};

const SubscribeCard = memo(
  ({
    subscriptionButtonLink = 'https://members.laurenohayon.com/itbz-subscriptions/#/',
    subscriptionButtonLabel = 'Subscribe to RYC® FIT now!',
  }: SubscribeCardProps) => {
    return (
      <Box
        sx={{
          cursor: 'default',
          maxHeight: '211px',
          width: 'clamp(300px, calc(100% - 64px), 494px)',
          height: 'clamp(150px, calc(100% - 64px), 211px)',
          padding: '8px',
          borderRadius: 6,
          display: 'grid',
          placeItems: 'center',
          gap: '4px',
          backgroundColor: theme => theme.palette.common.white,
        }}
        onClick={event => {
          event.stopPropagation();
        }}
      >
        <Typography
          sx={{
            fontSize: '16px',
            fontWeight: '600',
            textAlign: 'center',
            color: theme => theme.palette.grey[600],
          }}
        >
          You are currently not subscribed to RYC® FIT. Please subscribe to unlock the videos
        </Typography>
        <Button
          LinkComponent={Link}
          href={subscriptionButtonLink}
          target="_blank"
          sx={{
            width: 'fit-content',
          }}
          variant="contained"
          endIcon={
            <Box
              sx={{
                display: 'grid',
                placeItems: 'center',
                padding: '4px',
                width: 'clamp(20px, 100%, 46px)',
                aspectRatio: '1/1',
                backgroundColor: theme => theme.palette.primary.dark,
                borderRadius: '50%',
              }}
            >
              <ArrowRightIcon />
            </Box>
          }
        >
          <Typography
            sx={{
              fontSize: '16px',
              fontWeight: '600',
              textAlign: 'center',
              lineHeight: '26px',
              color: theme => theme.palette.common.white,
            }}
          >
            {subscriptionButtonLabel}
          </Typography>
        </Button>
      </Box>
    );
  },
);

const LockButton = memo(() => (
  <Box sx={{ position: 'relative' }}>
    <LockWithCircle fontSize="50px" color="white" />
  </Box>
));

type IProps = {
  subscriptionButtonLink?: string;
  subscriptionButtonLabel?: string;
  isLocked: boolean;
};

export const LockedCardOverlay: FC<IProps> = props => {
  const { isLocked, subscriptionButtonLink, subscriptionButtonLabel } = props;
  const [clicked, setClicked] = useState(false);

  const toggleBackdrop = useCallback(() => {
    setClicked(prev => !prev);
  }, []);

  if (!isLocked) {
    return null;
  }

  return (
    <Backdrop
      sx={{
        position: 'absolute',
        width: '100%',
        height: '100%',
        display: 'grid',
        placeItems: 'center',

        borderRadius: 6,
        overflow: 'hidden',
        cursor: 'pointer',
      }}
      open={isLocked}
      onClick={toggleBackdrop}
    >
      {clicked && (
        <SubscribeCard
          subscriptionButtonLink={subscriptionButtonLink}
          subscriptionButtonLabel={subscriptionButtonLabel}
        />
      )}
      {!clicked && <LockButton />}
    </Backdrop>
  );
};
