import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { DataStateHandler, PostLoading, Content, useGetByDevice, Slideshow } from '@onesource/components';
import { PageHeader } from 'components/page-header';
import { FC } from 'react';
import { useGetContentsPublicQuery, useGetInstructorBySlugQuery, useGetSubscriptionQuery } from '@onesource/services';
import { EAppNameEnum, TContent } from '@onesource/schemas';
import { useInstructorAllClassPageParams } from 'routes/fitness-membership/$instructorSlug/all-class';
import { InstructorSaleBanner } from 'components/instructor-sale-banner';
import { WP_MEMBERS_SITE } from 'config';

export const InstructorAllClass: FC = () => {
  const { isMobile } = useGetByDevice();

  const { instructorSlug } = useInstructorAllClassPageParams();

  const { data: instructorsData } = useGetInstructorBySlugQuery({
    instructorSlug,
  });

  const contentStates = useGetContentsPublicQuery({
    instructorId: instructorsData?.id ?? '',
    app: EAppNameEnum.FIT,
  });

  const { isFitSubscriptionActive } = useGetSubscriptionQuery();

  const instructorName = `${instructorsData?.firstName} ${instructorsData?.lastName}`;

  const sliderSettings = {
    slidesToShow: 3,
    slidesToScroll: 3,
    arrows: true,
    infinite: false,
    centerMode: false,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          centerMode: false,
          infinite: true,
        },
      },
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          centerMode: false,
          infinite: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: '15%',
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: '5%',
          infinite: true,
        },
      },
    ],
  };

  const affiliateId = instructorsData?.affiliateId;
  const subscriptionLink = `${WP_MEMBERS_SITE}/payment/ryc-fit/${affiliateId ? `?rxf=${affiliateId}` : ''}`;

  if (!instructorsData) {
    return null;
  }

  return (
    <Box>
      <PageHeader title={instructorName} />
      <Container maxWidth="xl">
        <Grid container spacing={{ xs: 2, md: 4 }} sx={{ justifyContent: 'center', pt: 5.5 }}>
          {isMobile ? (
            <Grid
              item
              xs={12}
              sx={{
                mt: 2,
                '& .slick-slider': { width: { xs: '100%', md: 'calc(100% - 64px)' }, margin: 'auto' },
                '& .slick-track': {
                  display: 'flex',
                  gap: { xs: 2, sm: 4, md: 5 },
                  alignItems: 'center',
                  pb: 2,
                },
              }}
            >
              <Slideshow
                slides={contentStates.data?.items || []}
                renderSlide={content => (
                  <Content
                    isLocked={!isFitSubscriptionActive}
                    content={content}
                    key={content.id}
                    subscriptionButtonLink={subscriptionLink}
                    subscriptionButtonLabel="Get My 7 Days Free"
                  />
                )}
                settings={sliderSettings}
              />
            </Grid>
          ) : (
            <Grid item md={12} sm={12} xs={12} lg={9}>
              <DataStateHandler
                queryResult={contentStates}
                wrapperSx={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '100%',
                }}
                entity="classes by instructors"
                loadingProps={{
                  loadingWrapperProps: {
                    flexDirection: 'row',
                  },
                  loadingItemProps: {
                    sm: 4,
                    md: 4,
                    xs: 12,
                  },
                }}
                gridProps={{ md: 4, sm: 6, xs: 12 }}
                LoadingComponent={PostLoading}
                IterativeComponent={(props: { item: TContent }) => {
                  return (
                    <Content
                      linkProps={{
                        search: {
                          instructorId: props.item.instructor.id,
                        },
                      }}
                      content={props.item}
                      isLocked={!isFitSubscriptionActive}
                      subscriptionButtonLink={subscriptionLink}
                      subscriptionButtonLabel="Get My 7 Days Free"
                    />
                  );
                }}
              />
            </Grid>
          )}
        </Grid>
      </Container>
      <InstructorSaleBanner
        title="Thoughtful fitness. Variety galore. </br>Community feeling."
        slug={instructorsData?.slug ?? ''}
        affiliateId={instructorsData?.affiliateId}
      />
    </Box>
  );
};
