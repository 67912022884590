import { useGetSeriesQuery, useGetSettingsQuery, useGetSubscriptionQuery } from '@onesource/services';
import { FC, useCallback, useMemo } from 'react';
import { AddEditPlaylistDialog, CommonSlideshow } from '@onesource/components';
import { usePlaylistMenuService } from 'components/add-to-playlist/service';
import { usePlaylistAction } from 'hooks/use-playlist-actions';
import { SettingsTypeEnum } from '@onesource/schemas';

export const SeriesFeature: FC = () => {
  const {
    contextClassId,
    onAddToPlaylist,
    createPlaylist,
    isCreatePending,
    onClosePlaylistDialog,
    onClickCreate,
    isCreatePlaylistDialogOpen,
  } = usePlaylistAction();
  const { isFitSubscriptionActive } = useGetSubscriptionQuery();
  const { data: seriesFeatureRes } = useGetSettingsQuery({
    type: SettingsTypeEnum.FEATURED_SERIES,
  });

  const featureSettingValues = seriesFeatureRes?.values;

  const { data: seriesResponse } = useGetSeriesQuery({
    seriesId: `${featureSettingValues?.seriesId}`,
    enabled: !!featureSettingValues?.isEnable,
  });

  const { setPlaylistMenu } = usePlaylistMenuService();

  const onClickAddPlaylistHandler = useCallback(
    (anchorEl: HTMLElement, contentId: string) => {
      setPlaylistMenu({
        contentId,
        anchorEl,
        onAddToPlaylist,
        onClickCreate,
      });
    },
    [onAddToPlaylist, onClickCreate, setPlaylistMenu],
  );

  const slides = useMemo(() => seriesResponse?.items.map(item => item.content) || [], [seriesResponse]);
  const sectionTitle = featureSettingValues?.title || '';

  if (!featureSettingValues?.title || !featureSettingValues?.seriesId || !featureSettingValues?.isEnable) {
    return null;
  }

  return (
    <>
      <CommonSlideshow
        slides={slides}
        sectionTitle={sectionTitle}
        onClickAddPlaylistHandler={onClickAddPlaylistHandler}
        isSubscriptionActive={isFitSubscriptionActive}
      />

      <AddEditPlaylistDialog
        isPending={isCreatePending}
        open={isCreatePlaylistDialogOpen}
        onClose={onClosePlaylistDialog}
        onSave={createPlaylist}
        contentId={contextClassId}
      />
    </>
  );
};
