import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import { TProfile } from '@onesource/schemas';
import { setSession } from '@onesource/services';
import { LinkProps, useNavigate, useSearch } from '@tanstack/react-router';
import axios, { AxiosResponse } from 'axios';
import { HOST_API } from 'config';
import { FC, useEffect, useState } from 'react';
import { z } from 'zod';

// example URL
// FIT_FE_URL/auth/sso?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9...&redirectTo="/classes"

const queryCheckSchema = z.object({
  token: z.string().min(300),
  redirectTo: z.string().optional(),
});

const DELAY = 1500;

export const SsoCheck: FC = () => {
  const navigate = useNavigate();
  const search = useSearch({ from: '/auth/sso' });
  const [tokenState, setIsTokenState] = useState<'ERROR' | 'SUCCESS' | 'INITIAL'>('INITIAL');
  const validate = queryCheckSchema.safeParse(search);

  useEffect(() => {
    // checking if the token is present in the URL
    if (!validate.success) {
      setIsTokenState('ERROR');
      return;
    }

    axios({
      method: 'GET',
      url: `${HOST_API}/auth/auth-check`,
      timeout: 10000,
      headers: {
        Authorization: `Bearer ${validate.data.token}`,
      },
    }).then((res: AxiosResponse<TProfile>) => {
      const isSuccess = res.status === 200;

      if (isSuccess) {
        setIsTokenState('SUCCESS');

        setSession({
          user: {
            email: res.data.email,
            name: `${res.data.firstName} ${res.data.lastName || ''}`,
          },
          token: validate.data.token,
        });

        // redirecting to the page after setting the session
        setTimeout(function () {
          const redirectTo: LinkProps['to'] | (string & {}) = validate.data?.redirectTo || '/';
          window.location.href = redirectTo;
        }, 200);
      }
    });
  }, [search, navigate, validate.success, validate.data?.token, validate.data?.redirectTo]);

  useEffect(() => {
    if (tokenState === 'ERROR') {
      setTimeout(function () {
        const loginPage: LinkProps['to'] = '/auth/login';
        window.location.href = loginPage;
      }, DELAY);
      return;
    }
  }, [tokenState, validate.data]);

  return (
    <Container
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 'calc(100vh - 439px)',
      }}
    >
      <Box sx={{ flexGrow: 1, textAlign: 'center', maxWidth: '400px' }}>
        <LinearProgress sx={{ width: '100%' }} />
        {tokenState === 'INITIAL' && <Typography mt={3}>Checking...</Typography>}
        {tokenState === 'SUCCESS' && <Typography mt={3}>Success! redirecting ...</Typography>}
        {tokenState === 'ERROR' && <Typography mt={3}>Token invalid, redirecting to login</Typography>}
      </Box>
    </Container>
  );
};
