import { createTheme, lighten } from '@mui/material/styles';

export type ColorOption = {
  light: string;
  main: string;
  dark: string;
};

export type ColorOptionWithContrast = ColorOption & {
  contrastText: string;
};

export type ColorPalette = {
  primary: ColorOptionWithContrast;
  secondary: ColorOptionWithContrast;
  warning: ColorOption;
  info: ColorOption;
  success: ColorOption;
  grey: {
    '50': string;
    '100': string;
    '200': string;
    '300': string;
    '400': string;
    '500': string;
    '600': string;
    '700': string;
    '800': string;
    '900': string;
  };
  common: {
    black: string;
    white: string;
  };
};

export const getTheme = (palette: ColorPalette) =>
  createTheme({
    palette,
    components: {
      MuiAppBar: {
        styleOverrides: {
          root: {
            boxShadow: 'none',
            backgroundColor: '#fafafa',
          },
        },
      },
      MuiAlert: {
        styleOverrides: {
          root: {
            borderRadius: '12px',
          },
        },
      },
      MuiListItemButton: {
        styleOverrides: {
          root: {
            marginBottom: '8px',
            borderRadius: '4px',
            backgroundColor: lighten(palette.primary.main, 0.9),
            borderLeft: `5px solid ${lighten(palette.primary.main, 0.9)}`,
            '&.Mui-selected': {
              backgroundColor: lighten(palette.primary.main, 0.8),
              borderColor: palette.primary.main,
            },
            '&:hover': {
              borderColor: lighten(palette.primary.main, 0.3),
              backgroundColor: lighten(palette.primary.main, 0.7),
            },
          },
        },
      },
      MuiButton: {
        defaultProps: {
          variant: 'contained',
          color: 'primary',
        },
        styleOverrides: {
          root: {
            color: palette.primary.contrastText,
            borderRadius: '30px',
            paddingLeft: '20px',
            paddingRight: '6px',
            paddingTop: '2px',
            paddingBottom: '2px',
            textTransform: 'none',
            justifyContent: 'space-between',
            minWidth: '170px',
            '&.MuiButton-containedPrimary': {
              background: `linear-gradient(95.28deg, ${palette.primary.main} 36.31%, ${palette.primary.light} 57.37%)`,
            },
            '&.MuiButton-containedInfo': {
              background: `linear-gradient(95.28deg, ${palette.info.main} 36.31%, ${palette.info.light} 57.37%)`,
            },
            '&.MuiButton-outlinedPrimary': {
              color: palette.primary.main,
            },
            '&.Mui-disabled': {
              color: palette.primary.contrastText,
              background: `${palette.grey[400]} !important`,
            },
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            padding: '12px 16px',
          },
        },
      },
      MuiFormControl: {
        styleOverrides: {
          root: {
            paddingTop: '2px',
            width: '100%',
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            '&.MuiOutlinedInput-root': {
              borderRadius: '30px',
              padding: '2px 16px',
            },
          },
        },
      },
      MuiDialogTitle: {
        styleOverrides: {
          root: {
            backgroundColor: palette.primary.main,
            color: palette.common.white,
            marginBottom: '12px',
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          paper: {
            borderRadius: 24,
          },
        },
      },
      MuiSlider: {
        styleOverrides: {
          root: {
            '& .MuiSlider-thumb': {
              background: '#fff',
              boxShadow: '0px 5px 8px 0px rgba(0, 0, 0, 0.25)',
            },
          },
        },
      },
      MuiTypography: {
        styleOverrides: {
          h2: ({ theme }) => ({
            fontSize: '48px',
            fontWeight: '600',
            color: palette.grey[900],
            [theme.breakpoints.down('md')]: {
              fontSize: '30px',
            },
            [theme.breakpoints.down(376)]: {
              fontSize: '22px',
            },
          }),
          h3: {
            color: palette.grey[800],
          },
          h4: {
            color: palette.grey[800],
          },
          h5: {
            color: palette.grey[800],
          },
          h6: ({ theme }) => ({
            fontSize: '20px',
            color: palette.grey[800],
            [theme.breakpoints.down('md')]: {
              fontSize: '18px',
            },
            [theme.breakpoints.down(376)]: {
              fontSize: '16px',
            },
          }),
          body1: ({ theme }) => ({
            fontSize: '18px',
            fontWeight: '600',
            [theme.breakpoints.down('md')]: {
              fontSize: '16px',
            },
            [theme.breakpoints.down(376)]: {
              fontSize: '14px',
            },
          }),
          body2: ({ theme }) => ({
            fontSize: '16px',
            fontWeight: '400',
            [theme.breakpoints.down('md')]: {
              fontSize: '14px',
            },
            [theme.breakpoints.down(376)]: {
              fontSize: '12px',
            },
          }),
        },
      },
      MuiAccordion: {
        styleOverrides: {
          root: {
            boxShadow: 'none',
          },
        },
      },
    },
    typography: {
      fontFamily: 'Montserrat, Arial, sans-serif',
    },
  });
