import { FC, useMemo } from 'react';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material';
import { useRouter } from '@tanstack/react-router';
import { BackLogo } from '@onesource/icons';

export type TBackButtonProps = {
  color?: 'primary' | 'white' | 'black';
};

export const BackButton: FC<TBackButtonProps> = props => {
  const { color = 'white' } = props;
  const router = useRouter();
  const theme = useTheme();

  const backBtnColor = useMemo(() => {
    if (color === 'white') {
      return theme.palette.common.white;
    }
    if (color === 'black') {
      return theme.palette.common.black;
    }

    if (color === 'primary') {
      return theme.palette.primary.light;
    }

    return theme.palette.common.white;
  }, [color, theme.palette.common.black, theme.palette.common.white, theme.palette.primary.light]);

  return (
    <IconButton sx={{ display: { xs: 'none', md: 'block' } }} onClick={() => router.history.back()}>
      <BackLogo
        style={{
          fontSize: '45px',
          color: backBtnColor,
        }}
      />
    </IconButton>
  );
};
