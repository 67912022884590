import type { SVGProps } from 'react';
const SvgVideoIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 25 25"
    fontSize="1rem"
    {...props}
  >
    <g fill="#CE3B4D">
      <path d="M10.343 20.293a.733.733 0 0 1-.732-.732v-7.845a.733.733 0 0 1 1.138-.61l5.884 3.922a.733.733 0 0 1 0 1.22L10.75 20.17a.73.73 0 0 1-.406.123m.732-7.209v5.108l3.832-2.554z" />
      <path d="M21.826 25H3.174A3.174 3.174 0 0 1 0 21.826V9.45a3.174 3.174 0 0 1 3.174-3.174h18.652A3.174 3.174 0 0 1 25 9.45v12.376A3.174 3.174 0 0 1 21.826 25m-17.92-1.465h17.188a2.44 2.44 0 0 0 2.441-2.441V10.182a2.44 2.44 0 0 0-2.441-2.441H3.906a2.44 2.44 0 0 0-2.441 2.441v10.912a2.44 2.44 0 0 0 2.441 2.441M21.914 4.603H3.086a.733.733 0 0 1 0-1.465h18.828a.732.732 0 1 1 0 1.465M19.561 1.465H5.44A.732.732 0 1 1 5.44 0H19.56a.732.732 0 1 1 0 1.465" />
    </g>
  </svg>
);
export default SvgVideoIcon;
