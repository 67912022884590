import Skeleton from '@mui/material/Skeleton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Theme, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { useLocalStorage } from 'usehooks-ts';

import { LeaderBoardTypeEnum, TLeaderBoardSchema, TLeaderBoardType } from '@onesource/schemas';
import { IGetContentsParams, useGetLeaderboardQuery } from '@onesource/services';
import { FC, memo, MouseEvent, useCallback, useState } from 'react';
import { TextFilterNav } from '../../text-filter-nav';
import { LeaderBoardRow } from './row';
import { DataStateHandler, useGetByDevice } from '@onesource/components';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import InfoPopover from './info-popover';

export type TLeaderboardProps = {
  title: string;
};

// const profileDetails = {
//   funFact: 'This is a new feature!',
//   achievement: ['Aligment', 'Better Breathing', 'Healing', 'Core Recovery', 'Diastasis Recti', 'Diastasis Recti'],
//   result: [1500, 1000],
// };

// const SliderSettings = {
//   slidesToShow: 3,
//   slidesToScroll: 3,
//   className: 'fit-fe-post-slider',
// };

const commonColumnStyles = (theme: Theme) => ({
  fontSize: { xs: '14px', sm: '18px' },
  lineHeight: 1.5,
  color: theme.palette.common.black,
  fontWeight: { xs: '600', sm: '700' },
  textAlign: 'left',
  borderBottom: { xs: `0px`, md: `10px solid white` },
  py: { xs: 2, md: 3.9 },
});

const LoadingComponent = memo(() => {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell sx={{ width: '70px' }}>
            <Skeleton variant="rectangular" />
          </TableCell>
          <TableCell>
            <Skeleton variant="rectangular" />
          </TableCell>
          <TableCell>
            <Skeleton variant="rectangular" />
          </TableCell>
          <TableCell>
            <Skeleton variant="rectangular" />
          </TableCell>
          <TableCell>
            <Skeleton variant="rectangular" />
          </TableCell>
          <TableCell>
            <Skeleton variant="rectangular" />
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {Array.from({ length: 12 }).map((_item, index) => (
          <TableRow key={index}>
            <TableCell sx={{ width: '70px' }}>
              <Skeleton variant="rectangular" />
            </TableCell>
            <TableCell>
              <Skeleton variant="rectangular" />
            </TableCell>
            <TableCell>
              <Skeleton variant="rectangular" />
            </TableCell>
            <TableCell>
              <Skeleton variant="rectangular" />
            </TableCell>
            <TableCell>
              <Skeleton variant="rectangular" />
            </TableCell>
            <TableCell>
              <Skeleton variant="rectangular" />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
});

export const Leaderboard: FC<TLeaderboardProps> = props => {
  const { title } = props;
  const theme = useTheme();
  const { isDesktop } = useGetByDevice();
  const [, setPage] = useState<number>(1);
  // const [selectedUser, setLeaderboardContext] = useState<TLeaderBoardSchema | null>(null);
  const [showTable, setShowTable] = useLocalStorage<boolean>('isShowLeaderboard', true);
  // const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [filters, setFilters] = useState<IGetContentsParams['filters']>({
    type: LeaderBoardTypeEnum.thisWeek,
    // page,
  });

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleInfoPopoverOpen = useCallback((event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleInfoPopoverClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const open = Boolean(anchorEl);

  const queryResult = useGetLeaderboardQuery({ filters });

  const showHideClickHandler = useCallback(() => {
    setShowTable(prev => !prev);
  }, [setShowTable]);

  const handleFilter = useCallback((_event: MouseEvent<HTMLElement>, value: string) => {
    setFilters(prev => ({
      ...prev,
      type: value as TLeaderBoardType,
    }));
  }, []);

  const onNext = useCallback((page: number) => {
    setPage(page);
  }, []);

  const onPrev = useCallback((page: number) => {
    setPage(page);
  }, []);

  // const handleAvatarClick = useCallback((item: TLeaderBoardSchema) => {
  //   setLeaderboardContext(item);
  //   setIsModalOpen(true);
  // }, []);

  // const handleCloseModal = useCallback(() => {
  //   setIsModalOpen(false);
  //   setLeaderboardContext(null);
  // }, []);

  const infoButtonStyle = !isDesktop
    ? {
        display: 'flex',
        alignItems: 'center',
        lineHeight: 3,
      }
    : undefined;

  return (
    <Box
      sx={{
        background: theme => theme.palette.grey[50],
        borderRadius: '100px 100px 0 0',
        py: { xs: 8, md: 12 },
      }}
    >
      <Container maxWidth="xl" sx={{ mt: 0 }}>
        <Box
          sx={{
            textAlign: 'center',
            display: 'flex',
            gap: 2,
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography variant="h2">{title}</Typography>
          <Button
            endIcon={
              <Box
                sx={{
                  background: theme.palette.primary.main,
                  borderRadius: '100%',
                  padding: '14px',
                  lineHeight: 0,
                }}
              >
                {showTable ? <IoIosArrowUp size={22} /> : <IoIosArrowDown size={22} />}
              </Box>
            }
            onClick={showHideClickHandler}
            sx={{ minWidth: 'unset' }}
          >
            {showTable ? 'Hide' : 'Show'}
          </Button>
        </Box>

        {showTable && (
          <>
            <Box
              sx={{
                marginY: '8px',
                textAlign: 'center',
                mt: { xs: 2, md: 3 },
              }}
            >
              <TextFilterNav<TLeaderBoardType>
                filter={filters.type}
                handleFilter={handleFilter}
                items={[
                  { label: 'This Week', value: LeaderBoardTypeEnum.thisWeek },
                  { label: 'Last Week', value: LeaderBoardTypeEnum.lastWeek },
                  { label: 'This Month', value: LeaderBoardTypeEnum.thisMonth },
                  { label: 'Previous Month', value: LeaderBoardTypeEnum.lastMonth },
                ]}
              />
            </Box>

            <Box sx={{ marginTop: { xs: 6, md: 10 } }}>
              <Box
                sx={{
                  borderRadius: '30px 30px 0 0',
                  overflow: 'hidden',
                  background: theme => theme.palette.common.white,
                }}
              >
                <DataStateHandler<TLeaderBoardSchema>
                  queryResult={queryResult}
                  onNext={onNext}
                  onPrev={onPrev}
                  entity="Leaderboard"
                  pagination={false}
                  gridProps={{
                    md: 3,
                    sm: 6,
                    xs: 12,
                  }}
                  LoadingComponent={LoadingComponent}
                  IterativeComponent={(props: { item: TLeaderBoardSchema }) => (
                    <LeaderBoardRow data={props.item} key={props.item.id} />
                  )}
                  isTable
                  tableConfig={{
                    columns: [
                      {
                        id: 'number',
                        children: 'No.',
                        sx: {
                          maxWidth: '40px',
                          ...commonColumnStyles(theme),
                        },
                      },
                      {
                        id: 'user',
                        children: 'User',
                        sx: {
                          ...commonColumnStyles(theme),
                          pl: 0,
                        },
                      },
                      {
                        id: 'location',
                        children: 'Location',
                        sx: {
                          ...commonColumnStyles(theme),
                          pl: 0,
                        },
                      },
                      {
                        id: 'badge',
                        children: '',
                        sx: {
                          ...commonColumnStyles(theme),
                          textAlign: 'center',
                        },
                      },
                      {
                        id: 'Days Active',
                        children: 'Days Active',
                        sx: {
                          ...commonColumnStyles(theme),
                          textAlign: 'center',
                        },
                      },
                      {
                        id: 'workouts',
                        children: 'Workouts',
                        sx: {
                          ...commonColumnStyles(theme),
                          textAlign: 'center',
                        },
                      },
                      {
                        id: 'points',
                        children: 'Points',
                        handleInfoPopoverOpen,
                        handleInfoPopoverClose,
                        sx: {
                          ...commonColumnStyles(theme),
                          textAlign: 'center',
                          ...infoButtonStyle,
                        },
                      },
                    ],
                    tableProps: {
                      sx: {
                        background: theme => theme.palette.common.white,
                      },
                    },
                  }}
                />
              </Box>
            </Box>
          </>
        )}
        <InfoPopover open={open} anchorEl={anchorEl} handlePopoverClose={handleInfoPopoverClose} />
      </Container>

      {/* <Modal open={isModalOpen} onClose={handleCloseModal}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '80vw',
            height: '90vh',
            borderRadius: '30px',
            background: theme => theme.palette.common.white,
            border: 0,
          }}
        >
          {selectedUser && (
            <>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Box
                  sx={{
                    display: 'flex',
                    flex: 1,
                    gap: 2,
                    borderRadius: '30px 0px 0px 0px',
                    background: darken(theme.palette.common.white, 0.066),
                    px: 6.5,
                    py: 4,
                  }}
                >
                  <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Avatar src={selectedUser.avatar} alt="avatar" sx={{ width: 250, height: 250 }} />
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, flexWrap: 'wrap' }}>
                    <Box sx={{ display: 'flex', gap: 1 }}>
                      <Typography variant="h4" sx={{ fontSize: '24px', fontWeight: '600' }}>
                        {selectedUser.name}
                      </Typography>
                      <Box
                        sx={{
                          height: '11px',
                          width: '11px',
                          background: theme => theme.palette.success.main,
                          borderRadius: '100%',
                        }}
                      ></Box>
                    </Box>
                    <Box sx={{ display: 'flex', gap: 1 }}>
                      <Checklist />
                      <Typography variant="body2" sx={{}}>
                        RYC FIT
                      </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', gap: 1 }}>
                      <Briefcase />
                      <Typography variant="body2" sx={{}}>
                        Flow + Pilates
                      </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', gap: 1 }}>
                      <Location />
                      <Typography variant="body2" sx={{}}>
                        Palmer, AK
                      </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', gap: 1 }}>
                      <Play />
                      <Typography variant="body2" sx={{}}>
                        Deep Core 3
                      </Typography>
                    </Box>
                    <Divider sx={{ width: '100%' }} />
                    <Box sx={{ display: 'flex', gap: 1 }}>
                      <Instagram />
                      <CiMail />
                      <Facebook />
                    </Box>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    flex: 1,
                    borderRadius: '0px 30px 0px 0px',
                    background: theme.palette.grey[50],
                    p: 4,
                  }}
                >
                  <Box sx={{ display: 'flex', gap: 1, position: 'absolute', top: '10px', right: '10px' }}>
                    <HandsOn style={{ cursor: 'pointer', fontSize: '24px' }} />
                    <Closeicon onClick={handleCloseModal} style={{ cursor: 'pointer', fontSize: '24px' }} />
                  </Box>
                  <Box sx={{ pt: 2, display: 'flex', gap: 3, flexWrap: 'wrap' }}>
                    <SmileIcon style={{ fontSize: '19px' }} />
                    <Typography variant="body1" sx={{ fontSize: '18px', fontWeight: '400', lineHeight: '19px' }}>
                      Fun Fact: {profileDetails.funFact}
                    </Typography>
                  </Box>
                  <Box sx={{ display: 'flex', gap: 3, mt: 3, mb: 3 }}>
                    <PriceTag style={{ fontSize: '34px' }} />
                    <Box style={{ display: 'flex', flexWrap: 'wrap', gap: '8px' }}>
                      {profileDetails.achievement.map((item, index) => (
                        <Chip
                          key={index}
                          label={item}
                          sx={{
                            fontSize: '16px',
                            textAlign: 'center',
                            padding: '4px 8px',
                            borderRadius: '30px',
                            boxShadow: '10px 24px 54px 0px rgba(0, 0, 0, 0.05)',
                            background: theme.palette.common.white,
                          }}
                        />
                      ))}
                    </Box>
                  </Box>
                  <Divider sx={{ width: '100%' }} />
                  <Box sx={{ display: 'flex', gap: 3, mt: 3, mb: 3 }}>
                    {profileDetails.result.map((item, index) => (
                      <PointBadge point={item} key={index} width="82px" />
                    ))}
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  pt: 0,
                  px: 4,
                  margin: '0 auto',
                  background: theme.palette.common.white,
                  '& .slick-slide': {
                    gap: 2,
                    display: 'flex',
                    alignItems: 'center',
                  },
                }}
              >
                <Grid container spacing={4}>
                  <Grid item xs={12}>
                    <Box sx={{ justifyContent: 'center', display: 'flex', gap: 2, mt: 2 }}>
                      <Typography
                        variant="h2"
                        sx={{
                          mb: 1.5,
                          textAlign: 'center',
                          color: darken(theme.palette.common.white, 0.799),
                          fontSize: '18px',
                          lineHeight: 1,
                        }}
                      >
                        My Favorite
                      </Typography>
                      <Heart
                        style={{
                          fontSize: '18px',
                          color: theme.palette.primary.main,
                          fill: theme.palette.primary.main,
                        }}
                      />
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      m: '0 auto',
                      paddingTop: '0 !important',
                      gap: 2,
                      '& .slick-track>div>div': { margin: 'auto' },
                    }}
                  >
                    <Slideshow
                      slides={favoritePosts}
                      renderSlide={favoritePost => <Post content={favoritePost} key={favoritePost.id} />}
                      settings={SliderSettings}
                    />
                  </Grid>
                </Grid>
              </Box>
            </>
          )}
        </Box>
      </Modal> */}
    </Box>
  );
};
