import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { alpha, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { MvPlay } from '@onesource/icons';
import { IndicatorEnum, TDeleteContentFromPlaylistRequestSchema, TDraggableItem } from '@onesource/schemas';
import { ellipsis } from '@onesource/utils';
import { Link } from '@tanstack/react-router';
import { motion } from 'framer-motion';
import { FC, useCallback } from 'react';
import { BiMoveVertical } from 'react-icons/bi';
import { HiDotsVertical } from 'react-icons/hi';
import { IoMdRemoveCircle } from 'react-icons/io';
import { MdDriveFileMove } from 'react-icons/md';
import { DraggableContentMobileViewCard } from './mobile-view';
import { useContextMenuService } from '../../context-menu';
import { useConfirmService } from '../../dialogs';
import { useGetByDevice } from '../../hooks';
import { ContentCategoryBadge } from '../../content-category-badge';
import { RequirementIndicator } from '../../requirement-indicator';
import { THandleSwitchParams } from '../../reorder';

export type IDraggableContentCardProps = {
  item: TDraggableItem;
  playlistId: string;
  enableDrag?: boolean;
  onRemove?: (params: TDeleteContentFromPlaylistRequestSchema) => void;
  onMove?: (item: TDraggableItem) => void;
  isDashboard?: boolean;
  isShowRatting?: boolean;
  isActionDisabled?: boolean;
  isMandatory?: boolean;
  hasAlternative?: boolean;
  onChangeMandatory?: (params: THandleSwitchParams) => void;
  onChangeHasAlternative?: (params: THandleSwitchParams) => void;
};

export const DraggableContentCard: FC<IDraggableContentCardProps> = props => {
  const {
    item,
    enableDrag,
    onRemove,
    onMove,
    playlistId,
    isDashboard = false,
    isShowRatting = true,
    isActionDisabled,
    onChangeMandatory,
    onChangeHasAlternative,
    isMandatory,
    hasAlternative,
  } = props;
  const { setContextMenu } = useContextMenuService();
  const { setConfirm } = useConfirmService();
  const theme = useTheme();
  const { isPortable } = useGetByDevice();

  const { content } = item;
  const playlistItemId = item.id.toString();
  const { id, category, equipments, averageRating, instructor, name } = content;

  const isActionMethod = !!onMove && !!onRemove;
  const isContextMenuShow: boolean = Boolean(isDashboard && !!onRemove);

  const isSwitchShow: boolean = isDashboard && !!onChangeMandatory && !!onChangeHasAlternative;

  const handleChangeMandatory = useCallback(
    (event: React.SyntheticEvent, checked: boolean) => {
      onChangeMandatory && onChangeMandatory({ contentId: id, checked });
    },
    [onChangeMandatory, id],
  );

  const handleChangeHashAlternative = useCallback(
    (event: React.SyntheticEvent, checked: boolean) => {
      onChangeHasAlternative && onChangeHasAlternative({ contentId: id, checked });
    },
    [onChangeHasAlternative, id],
  );

  const onClickRemoveHandler = useCallback(
    (itemId: string) => {
      setConfirm({
        title: 'Remove content',
        open: true,
        message: 'Are you sure you want to remove this content?',
        onConfirm: async () =>
          onRemove &&
          onRemove({
            itemId: Number(itemId),
            playlistId: Number(playlistId),
          }),
      });
    },
    [onRemove, playlistId, setConfirm],
  );

  const onClickContextMenuHandler = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>, itemId: string) => {
      event.preventDefault();

      setContextMenu({
        anchorEl: event.currentTarget,
        items: [
          {
            id: 'remove',
            label: isDashboard ? 'Remove from content' : 'Remove from playlist',
            icon: <IoMdRemoveCircle color={theme.palette.primary.main} size={24} />,
            color: 'info' as const,
            context: item,
            onClick: () => onClickRemoveHandler(itemId),
          },
          {
            id: 'move',
            label: isDashboard ? 'Move to content' : 'Move to another playlist',
            icon: (
              <MdDriveFileMove color={isDashboard ? theme.palette.grey[500] : theme.palette.primary.main} size={24} />
            ),
            color: 'info' as const,
            context: item,
            disabled: isDashboard,
            onClick: () => onMove && onMove(item),
          },
        ],
      });
    },
    [isDashboard, item, onClickRemoveHandler, onMove, setContextMenu, theme.palette.grey, theme.palette.primary.main],
  );

  if (isPortable) {
    return (
      <DraggableContentMobileViewCard
        content={content}
        itemId={playlistItemId}
        enableDrag={enableDrag}
        isDashboard={isDashboard}
        isActionMethod={isActionMethod}
        isContextMenuShow={isContextMenuShow}
        onClickContextMenuHandler={onClickContextMenuHandler}
      />
    );
  }

  return (
    <Card
      sx={{
        borderRadius: enableDrag ? `4px 16px 16px 4px` : 3,
        boxShadow: '0px 0px 20px 10px rgba(51, 51, 51, 0.05)',
        backgroundColor: enableDrag ? alpha(theme.palette.primary.main, 0.1) : theme.palette.common.white,
        transition: 'background-color 0.3s',
        '&:hover': {
          backgroundColor: !enableDrag ? alpha(theme.palette.primary.main, 0.1) : theme.palette.grey[100],
        },
      }}
    >
      <CardContent
        sx={{
          '&.MuiCardContent-root': {
            p: isSwitchShow ? `12px` : '16px 12px',
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              flex: 1,
              gap: 2,
            }}
          >
            {enableDrag && (
              <motion.div
                initial={{ opacity: 0.8, scale: 0.8 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{
                  repeat: Infinity,
                  duration: 1,
                  ease: 'easeInOut',
                  repeatType: 'reverse',
                }}
              >
                <BiMoveVertical color={theme.palette.primary.main} size={30} />
              </motion.div>
            )}

            <ContentCategoryBadge category={category} size="medium" />

            <Grid
              container
              spacing={2}
              alignItems="center"
              sx={{
                pt: 2,
                overflow: 'hidden',
              }}
            >
              <Grid item>
                <CardMedia
                  component="img"
                  sx={{
                    borderRadius: '50%',
                    width: {
                      xs: '50px',
                      md: '50px',
                    },
                    height: {
                      xs: '50px',
                      md: '50px',
                    },
                  }}
                  image={item.content.video.bannerUrl}
                />
              </Grid>

              <Grid item flexGrow={1}>
                <Link
                  to={`/classes/details/${item.content.id}`}
                  style={{
                    textDecoration: 'none',
                    pointerEvents: enableDrag || isDashboard ? 'none' : 'auto',
                  }}
                >
                  <Typography
                    color="common.black"
                    variant="body1"
                    sx={{ ...ellipsis, width: isSwitchShow ? '200px' : undefined }}
                  >
                    {name.substring(0, 55)}
                  </Typography>

                  <Typography variant="body2" color="text.secondary">
                    {instructor.firstName} {instructor.lastName}
                  </Typography>
                </Link>
              </Grid>

              {!isDashboard && (
                <Grid item>
                  <Link
                    to={`/classes/details/${id}`}
                    style={{
                      pointerEvents: enableDrag ? 'none' : 'auto',
                      textDecoration: 'none',
                    }}
                  >
                    <IconButton
                      size="large"
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        background: theme => (enableDrag ? theme.palette.grey[300] : theme.palette.primary.main),
                        color: theme => (enableDrag ? theme.palette.grey[400] : theme.palette.common.white),
                        height: '45px',
                        width: '45px',
                        boxShadow: enableDrag ? 'none' : `5px 5px 15px ${theme.palette.common.black}10`,
                        '&:hover': {
                          background: theme => theme.palette.primary.dark,
                        },
                      }}
                    >
                      <MvPlay fontSize={22} />
                    </IconButton>
                  </Link>
                </Grid>
              )}

              <Grid item sm={3}>
                {equipments.map(item => (
                  <Chip
                    key={item.slug}
                    label={item.name}
                    sx={{
                      mr: 1,
                      mt: 0.5,
                      mb: 0.5,
                      pl: 0.2,
                      pr: 1,
                      height: '28px',
                      backgroundColor: theme => theme.palette.grey[800],
                      color: 'white',
                    }}
                    size="small"
                  />
                ))}
              </Grid>
              {isShowRatting && (
                <Grid item mr={2}>
                  <Stack direction="row" justifyContent="space-between" spacing={2}>
                    <RequirementIndicator
                      indicator={IndicatorEnum.difficulty}
                      point={averageRating.difficulty}
                      isInteractive={false}
                    />

                    <RequirementIndicator
                      indicator={IndicatorEnum.intensity}
                      point={averageRating.intensity}
                      isInteractive={false}
                    />

                    <RequirementIndicator
                      indicator={IndicatorEnum.nerdiness}
                      point={averageRating.nerdiness}
                      isInteractive={false}
                    />
                  </Stack>
                </Grid>
              )}

              {(isContextMenuShow || isActionMethod) && (
                <Grid item>
                  <IconButton
                    disabled={enableDrag || isActionDisabled}
                    onClick={event => onClickContextMenuHandler(event, playlistItemId)}
                    size="large"
                    sx={{
                      mr: 0.5,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      background: theme.palette.primary.main,
                      color: theme.palette.common.white,
                      height: '45px',
                      width: '45px',
                      boxShadow: `5px 5px 10px ${theme.palette.common.black}10`,
                      '&:hover': {
                        background: theme.palette.primary.dark,
                      },
                    }}
                  >
                    <HiDotsVertical fontSize={24} />
                  </IconButton>
                </Grid>
              )}
              {isSwitchShow && (
                <Grid item>
                  <FormControlLabel
                    disabled={isActionDisabled}
                    onChange={handleChangeMandatory}
                    control={<Switch checked={isMandatory} />}
                    label="Mandatory"
                  />
                  <FormControlLabel
                    disabled={isActionDisabled}
                    onChange={handleChangeHashAlternative}
                    control={<Switch checked={hasAlternative} />}
                    label="Has alternative"
                  />
                </Grid>
              )}
            </Grid>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};
