import { darken, SxProps, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import { Energy, Sweat } from '@onesource/icons';
import { TIndicator } from '@onesource/schemas';
import { FC, useCallback, useMemo } from 'react';
import { SlEyeglass } from 'react-icons/sl';

export type TContentRatingProps = {
  indicator: TIndicator;
  point: number;
  fontSize?: number;
  showName?: boolean;
  color?: 'primary' | 'secondary';
  sx?: SxProps;
  onChange?: (newRating: number) => void;
  isInteractive?: boolean; // New prop to determine if the component should be interactive
};

export const RequirementIndicator: FC<TContentRatingProps> = props => {
  const {
    indicator,
    point,
    fontSize = 22,
    showName = false,
    sx,
    onChange,
    isInteractive = false,
    color = 'primary',
  } = props;
  const theme = useTheme();

  // State to track the current rating
  const iconList: Record<TIndicator, JSX.Element> = useMemo(() => {
    return {
      difficulty: <Energy fontSize={fontSize} color={theme.palette[color].main} />,
      intensity: <Sweat fontSize={fontSize} color={theme.palette[color].main} />,
      nerdiness: <SlEyeglass fontSize={fontSize} color={theme.palette[color].main} />,
    } as const;
  }, [color, fontSize, theme.palette]);

  // Handle the click event on bars
  const handleClick = useCallback(
    (index: number) => {
      if (isInteractive) {
        const newRating = index - 2;
        if (onChange) {
          onChange(newRating);
        }
      }
    },
    [isInteractive, onChange],
  );

  return (
    <Box
      display="flex"
      flexDirection="row"
      alignContent="flex-end"
      sx={{
        height: `${fontSize}px`,
        justifyContent: 'space-between',
        ...sx,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: showName ? 'space-between' : 'flex-start',
          gap: 2,
        }}
      >
        {iconList[indicator]}

        {showName && (
          <Box sx={{ textTransform: 'none' }}>
            {indicator.toString().charAt(0).toUpperCase() + indicator.slice(1).toLowerCase()}
          </Box>
        )}
      </Box>
      <Box display="flex" alignItems="flex-end" ml={0.8}>
        {[3, 4, 5, 6, 7].map(index => {
          const isFilled = point + 2 >= index;

          return (
            <Box
              key={index}
              sx={{
                width: '5px',
                height: index + 2 * index,
                mr: '3px',
                borderRadius: '5px',
                backgroundColor: isFilled ? theme.palette.common.black : darken(theme.palette.grey[300], 0.03),
                cursor: isInteractive ? 'pointer' : 'default',
              }}
              onClick={() => handleClick(index)}
            />
          );
        })}
      </Box>
    </Box>
  );
};
