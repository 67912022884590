import {
  TDeleteContentFromPlaylistRequestSchema,
  TDraggableItem,
  TRYCContentPath,
  TUpdateStepContentSchemaFE,
} from '@onesource/schemas';
import { FC } from 'react';
import { SortableContents } from '../sortable-contents';
import { DragEndEvent } from '@dnd-kit/core';

export type THandleSwitchParams = {
  contentId: string;
  checked: boolean;
  path?: TRYCContentPath;
};

interface IProps {
  playlistId?: string;
  contents: TDraggableItem[];
  isDashboard?: boolean;
  enableOrdering?: boolean;
  onMove?: (item: TDraggableItem) => void;
  onRemove?: (params: TDeleteContentFromPlaylistRequestSchema) => void;
  onChangeOrder?: (event: DragEndEvent) => void;
  setSortedItems?: (contents: TDraggableItem[]) => void;
  isShowRatting?: boolean;
  isActionDisabled?: boolean;
  onChangeMandatory?: (item: THandleSwitchParams) => void;
  onChangeHasAlternative?: (item: THandleSwitchParams) => void;
  step?: TUpdateStepContentSchemaFE[];
}

export const Reorder: FC<IProps> = props => {
  const {
    playlistId,
    contents,
    onMove,
    onRemove,
    enableOrdering,
    setSortedItems,
    onChangeOrder,
    isDashboard,
    isShowRatting,
    isActionDisabled,
    onChangeHasAlternative,
    onChangeMandatory,
    step,
  } = props;

  return (
    <SortableContents
      items={contents}
      setSortedItems={setSortedItems}
      onRemove={onRemove}
      onMove={onMove}
      enableDrag={enableOrdering}
      onChangeOrder={onChangeOrder}
      playlistId={playlistId ?? ''}
      isDashboard={isDashboard}
      isShowRatting={isShowRatting}
      isActionDisabled={isActionDisabled}
      onChangeMandatory={onChangeMandatory}
      onChangeHasAlternative={onChangeHasAlternative}
      step={step}
    />
  );
};
