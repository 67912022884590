import type { SVGProps } from 'react';
const SvgLiveIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 54 54"
    fontSize="1rem"
    {...props}
  >
    <circle cx={26.515} cy={26.515} r={26.515} fill="#FAE7E9" />
    <g fill="currentColor" clipPath="url(#liveIcon_svg__a)">
      <path d="M23.698 32.855a1.85 1.85 0 0 1-1.786-1.325 2.8 2.8 0 0 1-.098-.784 422 422 0 0 1-.006-3.35v-2.573a997 997 0 0 1 0-3.143c.004-1.238.755-2.102 1.828-2.102.363 0 .738.098 1.115.29q1.947.996 3.89 1.996c1.64.842 3.334 1.713 5.004 2.565.82.417 1.203.983 1.203 1.78 0 .796-.381 1.363-1.2 1.78-1.464.746-2.949 1.51-4.384 2.248-1.539.792-3.13 1.61-4.697 2.408-.276.14-.569.21-.87.21zm-.064-1.735 9.548-4.91-9.548-4.907zM15.28 39.466a.94.94 0 0 1-.662-.29 18.2 18.2 0 0 1-4.375-6.993c-.673-1.959-.98-3.943-.994-6.437.072-4.596 1.87-8.864 5.202-12.332.281-.294.592-.457.873-.457q.305 0 .563.245c.183.172.279.368.284.58.006.271-.14.575-.412.854-2.16 2.223-3.58 4.784-4.22 7.612-1.306 5.76.043 10.913 4.01 15.312q.088.1.183.194.102.1.186.196c.342.397.328.931-.033 1.27a.89.89 0 0 1-.605.247M40.027 39.463a.86.86 0 0 1-.589-.243.86.86 0 0 1-.29-.608c-.005-.252.11-.511.324-.73 2.397-2.452 3.903-5.298 4.477-8.46 1.007-5.557-.425-10.488-4.256-14.655l-.04-.044c-.172-.186-.334-.361-.424-.542-.178-.361-.044-.82.312-1.064a.9.9 0 0 1 .507-.164c.236 0 .462.1.652.287a17.8 17.8 0 0 1 2.793 3.577c1.738 2.93 2.558 6.046 2.579 9.808-.082 4.69-1.92 9.016-5.312 12.496-.215.221-.477.343-.735.343z" />
      <path d="M19.137 35.603q-.367-.002-.684-.322c-2.076-2.114-3.294-4.616-3.62-7.44-.472-4.074.726-7.651 3.556-10.637.227-.239.443-.377.875-.378.29.011.541.201.687.52.162.357.108.668-.177 1.01q-.201.239-.407.476c-.36.416-.733.847-1.046 1.325a11 11 0 0 0 1.302 13.738c.306.316.44.656.384.985a.85.85 0 0 1-.43.612.96.96 0 0 1-.44.113zM36.146 35.598a.83.83 0 0 1-.582-.248c-.402-.384-.37-.901.083-1.383 4.228-4.489 4.229-11.015.002-15.516-.41-.438-.477-.861-.195-1.258a.9.9 0 0 1 .725-.379q.174 0 .329.071c.137.063.285.175.43.324 2.38 2.468 3.616 5.499 3.67 9.008-.063 3.501-1.298 6.525-3.667 8.99-.241.25-.524.389-.795.389z" />
    </g>
    <defs>
      <clipPath id="liveIcon_svg__a">
        <path fill="#fff" d="M9.249 12.951h36.826v26.515H9.249z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgLiveIcon;
