import { useEffect } from 'react';
import { axiosAbortController, axiosServiceInstance, removeTokenFromAxios } from '../axios';
import { JwtExceptionEnumObj } from '@onesource/schemas';
import { logout } from './session';

/**
 * This hook will be used to logout the user
 * when the user is not logged in
 * @param cb - Callback function to be called when the user is logged out
 *
 *
 * NOTE: Q&A
 *  1. why we have to pass a callback and not doing it in the hook?
 *     ans: because if we initiate the query client in the hook, then the cache
 *     won't be removed. cause the app query client and hook query client will
 *     be different. that's why we are using the callback so that we can use the
 *     query client of the app.
 *  2. why js "location" and not tanstack-router "useNavigate" hook?
 *     ans: navigate could be used but because the tanstack-query cache was
 *     not being removed absolutely, so we are using the location.replace
 *     to force full page reload.
 */
export const useLogoutMutation = (cb?: () => void) => {
  useEffect(() => {
    axiosServiceInstance.interceptors.response.use(
      res => res,
      error => {
        if (error.response?.data?.error?.type === JwtExceptionEnumObj.enum.TokenExpired) {
          axiosAbortController.abort();
          removeTokenFromAxios();
          logout();
          cb?.();
        }

        return Promise.reject(error);
      },
    );
  }, [cb]);
};
