import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { TSeriesResponse } from '@onesource/schemas';
import { useGetAllSeriesQuery } from '@onesource/services';
import { FC, useCallback, useState } from 'react';
import { FitnessSeriesLoading, DataStateHandler, FitnessSeries } from '@onesource/components';
import Typography from '@mui/material/Typography';
import { darken, useTheme } from '@mui/material';

export const SeriesLoading = () => {
  return (
    <Grid container spacing={4}>
      {Array.from({ length: 6 }).map((_x, index) => (
        <Grid sm={6} xs={12} md={6} lg={4} item key={index}>
          <FitnessSeriesLoading />
        </Grid>
      ))}
    </Grid>
  );
};

export const SeriesPage: FC = () => {
  const theme = useTheme();
  const [page, setPage] = useState(1);

  const queryResult = useGetAllSeriesQuery({
    filters: {
      perPage: 8,
      page,
    },
  });

  const onNext = useCallback((page: number) => {
    setPage(page);
  }, []);

  const onPrev = useCallback((page: number) => {
    setPage(page);
  }, []);

  return (
    <Box sx={{ my: 4, background: theme => theme.palette.common.white }}>
      <Container maxWidth="xl">
        <Box sx={{ maxWidth: '729px', m: 'auto', pt: 2, pb: 5.5, display: { xs: 'none', md: 'block' } }}>
          <Typography variant="body2" sx={{ textAlign: 'center', color: darken(theme.palette.common.white, 0.799) }}>
            Each collection has been thoughtfully curated to support your unique FITness goals or needs offering a
            tailored approach to your success.
          </Typography>
        </Box>
        <DataStateHandler
          wrapperSx={{
            mb: 2,
          }}
          queryResult={queryResult}
          onNext={onNext}
          onPrev={onPrev}
          entity="Series"
          gridProps={{ sm: 6, xs: 12, md: 6, lg: 4 }}
          LoadingComponent={SeriesLoading}
          IterativeComponent={(props: { item: TSeriesResponse }) => <FitnessSeries fitnessItem={props.item} />}
        />
      </Container>
    </Box>
  );
};
