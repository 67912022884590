import Container from '@mui/material/Container';
import { LoadingError } from '@onesource/components';
import { EAppNameEnum } from '@onesource/schemas';
import { useGetContentsPublicQuery, useGetInstructorBySlugQuery } from '@onesource/services';
import { useNavigate } from '@tanstack/react-router';
import { InstructorDetailsBody } from 'components/sections/single-instructor-details';
import { InstructorDetailsLoading } from 'components/sections/single-instructor-details/loading';
import { useEffect } from 'react';
import { useInstructorDetailsPublicPageParams } from 'routes/fitness-membership/$instructorSlug';

export const InstructorDetailsPublic = () => {
  const { instructorSlug } = useInstructorDetailsPublicPageParams();
  const { data: instructor, isLoading, error, refetch } = useGetInstructorBySlugQuery({ instructorSlug });
  const navigate = useNavigate();

  useEffect(() => {
    if (/[A-Z]/.test(instructorSlug)) {
      window.location.href = `/fitness-membership/${instructorSlug.toLowerCase()}`;
    }
  }, [instructorSlug, navigate]);

  const {
    data: instructorContent,
    isLoading: isContentLoading,
    error: contentError,
  } = useGetContentsPublicQuery({
    app: EAppNameEnum.FIT,
    instructorId: instructor?.id ?? '',
  });

  if (isLoading || isContentLoading) {
    return <InstructorDetailsLoading />;
  }

  if (error) {
    return (
      <Container maxWidth="xl">
        <LoadingError
          sx={{ width: '100%', my: 4 }}
          entity="Instructor"
          message={error.message}
          refetch={refetch}
          errorCode={error.code}
        />
      </Container>
    );
  }

  if (contentError) {
    return (
      <Container maxWidth="xl">
        <LoadingError
          sx={{ width: '100%', my: 4 }}
          entity="Instructor Content"
          message={contentError.message}
          refetch={refetch}
          errorCode={contentError.code}
        />
      </Container>
    );
  }

  if (!instructorSlug || !instructor || !instructorContent) {
    return null;
  }
  return <InstructorDetailsBody instructorContent={instructorContent} instructor={instructor} />;
};
