import { FC, useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { darken, lighten, useTheme } from '@mui/material';
import Container from '@mui/material/Container';
import { LiveIcon, MvPlay } from '@onesource/icons';
import { TSlideProps } from '@onesource/services';
import { useSlideBadgeImage } from '../use-slide-badge-image';

export const HeroLiveSlide: FC<TSlideProps> = props => {
  const {
    sectionTitle,
    sectionSubtitle,
    sectionDescription,
    actionButton,
    bgImg,
    classDate,
    status,
    badgeImage,
    badgeImagePosition,
  } = props;
  const theme = useTheme();
  const [timeRemaining, setTimeRemaining] = useState<{
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
  } | null>(null);

  const [classStatus, setClassStatus] = useState<'upcoming' | 'now' | 'ended'>('upcoming');
  const { BadgeImage, containerAfterCSS } = useSlideBadgeImage({
    bgImg,
    badgeImage,
    badgeImagePosition,
  });

  useEffect(() => {
    if (classDate) {
      const classTime = DateTime.fromISO(classDate, { zone: 'local' });

      const updateTime = () => {
        const now = DateTime.now().setZone('local');
        const difference = classTime.diff(now, ['days', 'hours', 'minutes', 'seconds']).toObject();

        if (difference.days! > 0 || difference.hours! > 0 || difference.minutes! > 0 || difference.seconds! > 0) {
          const remaining = {
            days: Math.floor(difference.days || 0),
            hours: Math.floor(difference.hours || 0),
            minutes: Math.floor(difference.minutes || 0),
            seconds: Math.floor(difference.seconds || 0),
          };

          setTimeRemaining(remaining);
          setClassStatus('upcoming');
        } else if (now <= classTime.plus({ hours: 2 })) {
          setClassStatus(status === 'ENDED' ? 'ended' : 'now');
        } else {
          setClassStatus('ended');
        }
      };

      // updateTime();
      const timer = setInterval(updateTime, 1000);

      return () => clearInterval(timer);
    }
  }, [classDate, status]);

  const isCountdown =
    (status === 'SCHEDULED' || classStatus === 'now') && !!timeRemaining?.minutes && !!timeRemaining?.seconds;

  return (
    <Box
      sx={{
        position: 'relative',
        backgroundRepeat: 'no-repeat ',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundImage: `url(${bgImg})`,
        //Todo: add background to hero slide
        // backgroundImage: `url(assets/background/event-slide-background.png)`,
        width: '100%',
        minHeight: {
          xs: '468px',
          md: '722px',
        },
        pt: { xs: 0, md: 11 },
        '&:before': {
          content: "''",
          position: 'absolute',
          height: '100%',
          width: {
            xs: '100%',
          },
          top: 0,
          background: `linear-gradient(to left, transparent, ${theme.palette.common.black}) left top / 50% 100% no-repeat`,
        },
        ...containerAfterCSS,
      }}
    >
      <BadgeImage />

      <Container maxWidth="xl" sx={{ position: 'relative', zIndex: 1, overflow: 'hidden' }}>
        <Grid container spacing={4} sx={{}}>
          <Grid
            item
            md={3}
            xs={12}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              minHeight: {
                xs: '400px',
                md: '722px',
              },
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                minHeight: '350px',
                pt: { xs: 6 },
                pb: { xs: 6 },
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    mt: { xs: 0, md: 9 },
                    mb: { xs: 2, md: 3 },
                    gap: 3,
                  }}
                >
                  <Box
                    sx={{
                      '& svg': {
                        fontSize: { xs: '30px', md: '63px' },
                      },
                    }}
                  >
                    {status === 'SCHEDULED' && (
                      <LiveIcon
                        style={{
                          color: theme.palette.common.white,
                          fill: theme.palette.secondary.main,
                        }}
                      />
                    )}

                    {status !== 'SCHEDULED' && (
                      <LiveIcon
                        style={{
                          color: theme.palette.common.black,
                          fill: lighten(theme.palette.secondary.main, 0.8),
                        }}
                      />
                    )}
                  </Box>
                  <Typography
                    variant="h2"
                    sx={{
                      fontSize: { xs: '30px', md: '48px' },
                      fontWeight: { xs: '700', md: '600' },
                      lineHeight: { xs: 1, md: '63px' },
                      color: theme => theme.palette.common.white,
                      '& br': { display: { xs: 'none', md: 'block' } },
                    }}
                  >
                    <span dangerouslySetInnerHTML={{ __html: sectionTitle }} />
                  </Typography>
                </Box>
                <Typography
                  variant="body1"
                  sx={{
                    textTransform: 'uppercase',
                    color: theme => theme.palette.common.white,
                  }}
                >
                  <span dangerouslySetInnerHTML={{ __html: sectionSubtitle }} />
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    mb: 1,
                    color: theme => theme.palette.common.white,
                    lineHeight: 1.6,
                  }}
                >
                  <span dangerouslySetInnerHTML={{ __html: sectionDescription }} />
                </Typography>
              </Box>
              {isCountdown && (
                <Box>
                  <Typography variant="body2" sx={{ color: theme.palette.common.white, mb: 2 }}>
                    Going Live In
                  </Typography>
                  <Box
                    sx={{
                      borderRadius: '30px',
                      background: lighten(theme.palette.primary.main, 0.9),
                      boxShadow: '10px 24px 54px 0px rgba(0, 0, 0, 0.05)',
                      display: 'flex',
                      width: '279px',
                      alignItems: 'center',
                      justifyContent: 'center',
                      gap: 5,
                      py: 2.5,
                      px: 4,
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 1,
                        justifyContent: 'center',
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: '35px',
                          fontWeight: '600',
                          lineHeight: 1,
                          color: darken(theme.palette.common.white, 0.799),
                        }}
                      >
                        {timeRemaining.days}
                      </Typography>
                      <Typography sx={{ color: darken(theme.palette.common.white, 0.799) }}>days</Typography>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 1,
                        justifyContent: 'center',
                      }}
                    >
                      <Typography
                        variant="body2"
                        sx={{
                          fontSize: '35px',
                          fontWeight: '600',
                          lineHeight: 1,
                          color: darken(theme.palette.common.white, 0.799),
                        }}
                      >
                        {timeRemaining.hours}
                      </Typography>
                      <Typography variant="body2" sx={{ color: darken(theme.palette.common.white, 0.799) }}>
                        hours
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 1,
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <Typography
                        variant="body2"
                        sx={{
                          fontSize: '35px',
                          fontWeight: '600',
                          lineHeight: 1,
                          color: darken(theme.palette.common.white, 0.799),
                        }}
                      >
                        {timeRemaining.minutes}
                      </Typography>
                      <Typography variant="body2" sx={{ color: darken(theme.palette.common.white, 0.799) }}>
                        minutes
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              )}
              {actionButton && (
                <Box>
                  <Typography
                    variant="body2"
                    sx={{ color: theme.palette.common.white, mb: 1, fontSize: '16px', fontWeight: '600' }}
                  >
                    {status === 'STARTED' && <>Class started</>}
                    {status !== 'STARTED' && status !== 'ENDED' && !timeRemaining && <>Going live sooon</>}
                  </Typography>

                  {(status === 'STARTED' || classStatus === 'now') && (
                    <Box>
                      <Button
                        endIcon={
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              height: '50px',
                              width: '50px',
                              borderRadius: '50%',
                              background: theme.palette.primary.main,
                              color: theme.palette.common.white,
                              boxShadow: `5px 5px 15px ${theme.palette.common.black}20`,
                            }}
                          >
                            <MvPlay />
                          </Box>
                        }
                        {...actionButton}
                        sx={{ paddingLeft: '20px' }}
                      >
                        {actionButton.children}
                      </Button>
                    </Box>
                  )}

                  {status === 'ENDED' && (
                    <Typography
                      variant="body2"
                      sx={{ color: theme.palette.common.white, mb: 3, fontSize: '24px', fontWeight: '600' }}
                    >
                      Class ended
                      <br />
                      Replay available shortly.
                    </Typography>
                  )}
                </Box>
              )}
            </Box>
          </Grid>
          {/* //Todo: add hero image */}
          {/* <Grid
            item
            md={9}
            sx={{
              display: { xs: 'none', md: 'flex' },
              flexDirection: 'row',
              gap: 2,
            }}
          >
            {Array.from({ length: 3 }).map((_x, index) => (
              <Card
                key={index}
                sx={{
                  transform: 'skew(-13deg)',
                  width: { sm: '400px', md: '320px' },
                  height: {
                    xs: '468px',
                    md: '810px',
                  },
                }}
              >
                <CardMedia
                  component="img"
                  image={heroImage[index]}
                  srcSet={heroImage[index]}
                  sx={{
                    width: '100%',
                    height: '100%',
                  }}
                />
              </Card>
            ))}
          </Grid> */}
        </Grid>
      </Container>
    </Box>
  );
};
