import { darken, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import {
  AddEditPlaylistDialog,
  AddRatingDialog,
  CommentProps,
  CommentsSection,
  ContentCardSmall,
  LoadingError,
  MovePlaylistItemDialog,
  Ratings,
  TOnSaveParams,
  useConfirmService,
} from '@onesource/components';
import { EAppNameEnum, TCompleteContentAction } from '@onesource/schemas';
import { TDraggableItem } from '@onesource/schemas';

import {
  useCompleteContentMutation,
  useCreatePlaylistMutation,
  useGetAllPlaylistsQuery,
  useGetPlaylistQuery,
} from '@onesource/services';
import { useNavigate } from '@tanstack/react-router';
import { VimeoPlayer } from 'components/sections/vimeo-player';
import { VideoHeader } from 'components/video-header';
import { usePlaylistAction } from 'hooks/use-playlist-actions';
import { FC, ReactNode, SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { usePlaylistIdParams } from 'routes/_protected/playlists/$playlistId/play';

interface TabPanelProps {
  children?: ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      {value === index && <Box sx={{ py: 3 }}>{children}</Box>}
    </div>
  );
}

export const PlaylistPlayer: FC = () => {
  const [tabValue, setTabValue] = useState(0);
  const theme = useTheme();
  const navigate = useNavigate();
  const [isRatingDialogOpen, setRatingDialogOpen] = useState(false);
  const { playlistId } = usePlaylistIdParams();
  const [isCreatePlaylistDialogOpen, setIsCreateNewPlaylistDialogOpen] = useState(false);
  const playlistQueryResult = useGetPlaylistQuery(playlistId);

  const { setConfirm } = useConfirmService();

  const [contextPlaylistItem, setContextPlaylistItem] = useState<TDraggableItem | null>(null);

  const { data: playlistRes, isLoading: isPlaylistQueryLoading, error: isPlaylistError } = playlistQueryResult;
  const totalVideos = playlistRes?.items?.length || 0;
  const videoLength = playlistRes?.items?.reduce((acc, curr) => acc + Number(curr.content.video.duration), 0) || 0;
  const hours = Math.floor(videoLength / 60);
  const minutes = videoLength % 60;
  const playlistItems = useMemo(() => playlistRes?.items || [], [playlistRes?.items]);
  const isContentAvailable = playlistItems.length && !isPlaylistQueryLoading;

  const {
    isMovePending,
    onCloseMovePlaylistItemDialog,
    movePlaylistItemHandler,
    movePlaylistItemContext,
    removePlaylistItem,
    openMovePlaylistItemDialogHandler,
  } = usePlaylistAction(playlistRes);

  const { data: allPlaylistRes, error: isPlaylistsError, refetch: refetchPlaylists } = useGetAllPlaylistsQuery();

  const { mutateAsync: createPlaylist, isPending: isPlaylistCreatePending } = useCreatePlaylistMutation();
  const { mutateAsync: completeContentMutation } = useCompleteContentMutation();

  const completeContentHandler = useCallback(
    async (contentId: string, action: TCompleteContentAction) => {
      await completeContentMutation({ contentId, action, app: EAppNameEnum.FIT });
    },
    [completeContentMutation],
  );

  const onVideoEndHandler = useCallback((videoId: string) => {
    console.log('Video ended', videoId);
  }, []);

  const handleOpenCreatePlayList = useCallback(() => {
    setIsCreateNewPlaylistDialogOpen(true);
  }, []);

  const handleCloseCreatePlayList = useCallback(() => {
    setIsCreateNewPlaylistDialogOpen(false);
  }, []);

  const handleSavePlayList = useCallback(
    async (params: TOnSaveParams) => {
      const { name, contentId } = params;
      await createPlaylist(
        { name, contentId },
        {
          onSuccess: () => {
            handleCloseCreatePlayList();
          },
        },
      );
    },
    [createPlaylist, handleCloseCreatePlayList],
  );

  const handleChange = useCallback((_event: SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  }, []);

  const handleRatingDialogOpen = useCallback(() => {
    setRatingDialogOpen(true);
  }, []);

  const handleRatingDialogClose = useCallback(() => {
    setRatingDialogOpen(false);
  }, []);

  const setNextVideoToPlay = useCallback(() => {
    const currentContentIndex = playlistItems.findIndex(playlistItem => playlistItem.id === contextPlaylistItem?.id);
    const itemsCount = playlistItems.length;
    const nextContent =
      itemsCount > 1 ? playlistItems[currentContentIndex + 1] || playlistItems[currentContentIndex - 1] : null;

    setContextPlaylistItem(nextContent || null);
  }, [contextPlaylistItem?.id, playlistItems]);

  const onClickRemovePlaylistItemHandler = useCallback(
    (playlistItem: TDraggableItem) => {
      setConfirm({
        title: 'Remove content',
        open: true,
        message: 'Are you sure you want to remove this content from the playlist?',
        onConfirm: async () => {
          removePlaylistItem({ itemId: playlistItem.id, playlistId: Number(playlistId) }, setNextVideoToPlay);
        },
      });
    },
    [setConfirm, removePlaylistItem, playlistId, setNextVideoToPlay],
  );

  const [comments, setComments] = useState<Array<CommentProps & { id: string | number }>>([
    {
      id: 1,
      avatarUrl: 'https://i.pravatar.cc/300',
      username: 'John Doe',
      date: '2 weeks ago',
      comment: 'Great video, very informative!',
    },
    {
      id: 2,
      avatarUrl: 'https://i.pravatar.cc/300',
      username: 'Janny Keplar',
      date: '2 weeks ago',
      comment: 'Another Great video, very informative!',
    },
  ]);

  const handleSendComment = useCallback((comment: string) => {
    setComments(prev => [
      ...prev,
      {
        avatarUrl: 'https://i.pravatar.cc/300',
        id: prev.length + 1,
        username: 'You',
        date: 'Just now',
        comment: comment,
      },
    ]);
  }, []);

  useEffect(() => {
    setContextPlaylistItem(prev => {
      if (!prev && playlistItems.length) {
        return playlistItems[0];
      }
      return prev;
    });
  }, [playlistItems]);

  if (isPlaylistQueryLoading) {
    return null;
  }

  if (!isContentAvailable) {
    navigate({
      to: `/playlists`,
      params: {
        playlistId,
      },
    });
  }

  return (
    <Box sx={{ py: { xs: 3, md: 5 }, background: theme => theme.palette.common.white, overflow: 'hidden' }}>
      <Container maxWidth="xl">
        {isPlaylistsError && (
          <LoadingError
            sx={{ width: '100%' }}
            entity="Playlists"
            message={isPlaylistsError.message}
            refetch={refetchPlaylists}
            errorCode={isPlaylistsError.code}
          />
        )}

        {isPlaylistError && (
          <LoadingError
            sx={{ width: '100%' }}
            entity="Playlist"
            message={isPlaylistError.message}
            refetch={refetchPlaylists}
            errorCode={isPlaylistError.code}
          />
        )}

        {contextPlaylistItem && (
          <Grid container spacing={3}>
            <Grid xs={12} item>
              <VideoHeader content={contextPlaylistItem.content} />
            </Grid>

            <Grid item xs={12} md={9}>
              <VimeoPlayer
                content={contextPlaylistItem.content}
                onVideoEnd={onVideoEndHandler}
                onMarkComplete={completeContentHandler}
                playlists={allPlaylistRes}
                onClickCreate={handleOpenCreatePlayList}
              />
            </Grid>

            <Grid item xs={12} md={3}>
              <Box
                sx={{
                  pb: 2,
                  height: { xs: 'auto', md: '632px' },
                  overflowY: 'auto',
                }}
              >
                {playlistItems.map(content => (
                  <ContentCardSmall
                    onClick={() => setContextPlaylistItem(content)}
                    selected={content.id === contextPlaylistItem.id}
                    key={content.id}
                    playlistItem={content}
                    onMove={openMovePlaylistItemDialogHandler}
                    onRemove={onClickRemovePlaylistItemHandler}
                  />
                ))}
              </Box>
              <Box
                sx={{
                  py: 1.5,
                }}
              >
                <b>{totalVideos} Videos</b> | {hours} h {minutes} m playtime
              </Box>
            </Grid>

            <Grid item xs={12} md={9}>
              <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <Tabs value={tabValue} onChange={handleChange}>
                    <Tab label="About" />
                    {/* <Tab label="Comments" {...a11yProps(1)} /> */}
                  </Tabs>
                </Box>
                <CustomTabPanel value={tabValue} index={0}>
                  <Typography
                    variant="body2"
                    sx={{
                      mb: 3,
                      color: darken(theme.palette.common.white, 0.799),
                    }}
                  >
                    <span dangerouslySetInnerHTML={{ __html: contextPlaylistItem.content.video.description }} />
                  </Typography>
                  <Box sx={{ width: '100%', mb: 5 }}>
                    {contextPlaylistItem.content.equipments.map(item => (
                      <Chip
                        key={item.slug}
                        label={item.name}
                        sx={{
                          mr: 1,
                          mb: 1,
                          px: 2,
                          height: '28px',
                          backgroundColor: theme => theme.palette.grey[800],
                          color: 'white',
                        }}
                        size="small"
                      />
                    ))}
                  </Box>
                </CustomTabPanel>
                <CustomTabPanel value={tabValue} index={1}>
                  <CommentsSection comments={comments} handleSendComment={handleSendComment} />
                </CustomTabPanel>
              </Box>
            </Grid>
            <Grid item md={3} sx={{ display: { xs: 'none', md: 'block' } }}>
              <Ratings openModal={handleRatingDialogOpen} title="Ratings" post={contextPlaylistItem.content} />
            </Grid>
          </Grid>
        )}
      </Container>

      <AddEditPlaylistDialog
        isPending={isPlaylistCreatePending}
        open={isCreatePlaylistDialogOpen}
        onClose={handleCloseCreatePlayList}
        onSave={handleSavePlayList}
        contentId={contextPlaylistItem?.content?.id}
      />

      <MovePlaylistItemDialog
        contextPlaylistItem={movePlaylistItemContext}
        onClose={onCloseMovePlaylistItemDialog}
        onSave={movePlaylistItemHandler}
        isPending={isMovePending}
      />

      {contextPlaylistItem && (
        <AddRatingDialog
          content={contextPlaylistItem.content}
          open={isRatingDialogOpen}
          onClose={handleRatingDialogClose}
        />
      )}
    </Box>
  );
};
