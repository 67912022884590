import type { SVGProps } from 'react';
const SvgGlass = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 25 11"
    fontSize="1rem"
    {...props}
  >
    <g clipPath="url(#glass_svg__a)">
      <path
        fill="currentColor"
        d="m17.512.876.447.646c.255-.163.491-.317.729-.467a1.78 1.78 0 0 1 2.167.21l2.134 2.068c.056.054.109.113.199.207h-.315c-2.482 0-4.964.002-7.447-.002-.431 0-.826.098-1.195.316a.6.6 0 0 1-.28.073q-1.446.008-2.893 0a.63.63 0 0 1-.3-.082 2.3 2.3 0 0 0-1.173-.306c-2.482.004-4.964.002-7.447.002H1.82c.078-.084.123-.137.172-.185q1.092-1.06 2.187-2.12c.507-.488 1.433-.598 2.044-.234.271.16.532.336.821.52l.428-.616C6.681.203 5.787-.131 4.721.073c-.436.084-.79.297-1.1.598Q2.21 2.044.79 3.41a1 1 0 0 1-.256.185C0 3.847-.172 4.566.198 5.032c.025.031.05.068.083.09.504.327.6.792.556 1.348-.053.648-.003 1.295.236 1.92.577 1.51 2.064 2.545 3.722 2.555.853.005 1.714.072 2.553-.143 2.119-.54 3.497-1.833 4.143-3.858.126-.393.42-.63.844-.675.372-.04.694.08.998.26.06.036.101.125.122.197.649 2.284 2.162 3.7 4.568 4.132 1.044.188 2.127.205 3.172-.052 1.752-.433 2.966-1.972 2.982-3.72.004-.415-.008-.83.007-1.243a.56.56 0 0 1 .13-.315c.132-.157.297-.286.439-.435.394-.417.302-1.16-.161-1.441a3.2 3.2 0 0 1-.55-.41C23.16 2.4 22.285 1.546 21.407.697c-.81-.783-2.131-.924-3.102-.328-.261.16-.515.33-.793.508m1.233 3.444c1.131 0 2.264-.004 3.396.002.706.003 1.223.502 1.23 1.183a77 77 0 0 1 0 1.584c-.015 1.416-1.026 2.694-2.462 2.97-.689.133-1.417.112-2.126.1-2.509-.037-4.623-2.105-4.668-4.536-.015-.806.483-1.302 1.31-1.303q1.66-.001 3.32 0m-12.478 0q1.685-.001 3.37 0c.747.003 1.258.511 1.254 1.236-.01 2.078-1.54 3.956-3.632 4.466-.9.22-1.82.196-2.726.12-1.509-.129-2.663-1.214-2.85-2.649-.083-.624-.044-1.263-.048-1.896-.005-.795.494-1.275 1.312-1.276zm5.303.404h1.863l-.158.896c-.51-.189-1.04-.173-1.543-.008zm12.471.02-.253-.412h.432zM1.22 4.326c-.08.13-.147.242-.26.43l-.162-.43z"
      />
    </g>
    <defs>
      <clipPath id="glass_svg__a">
        <path fill="#fff" d="M25 0H0v11h25z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgGlass;
