import { useGetUserWatchedContentIDsQuery } from '@onesource/services';
import { Outlet, createFileRoute, useParams } from '@tanstack/react-router';

const RouteComponent = () => {
  useGetUserWatchedContentIDsQuery();
  const { classId } = useParams({ strict: false });

  if (classId) {
    return <Outlet />;
  }

  return <Outlet />;
};

export const Route = createFileRoute('/_protected/classes')({
  component: RouteComponent,
});
